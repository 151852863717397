import { useMutation, useQuery } from "@apollo/client"
import { Button, Checkbox, Divider, Fab, Grid, IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import React, { Fragment, useState } from 'react'
import { FaDirections, FaEllipsisH, FaEllipsisV, FaEnvelope, FaEnvelopeOpenText, FaExclamationTriangle, FaListAlt, FaPencilAlt, FaPlusCircle, FaReplyAll, FaTimesCircle } from 'react-icons/fa'
import { MdPrint } from "react-icons/md"
import { CONTACT_RM_MUTATION, DESTS, DEST_MUTATION, QUERY_ALL_DATA } from '../apollo/resolvers'
import Container from '../components/Container'
import Error from '../components/Error'
import Expeditor from '../components/Expeditor'
import Layout from '../components/Layout'
import MaterialTableInstance from '../components/MaterialTableInstance'
import ARmodal from '../components/Modals/ARmodal'
import BatchBorderou from '../components/Modals/BatchBorderou'
import BatchConfirmari from '../components/Modals/BatchConfirmari'
import BatchPlicuri from '../components/Modals/BatchPlicuri'
import ContactModal from '../components/Modals/ContactModal'
import PlicModal from '../components/Modals/PlicModal'
import RichCard from '../components/RichCard'
import { useApolloClient } from "@apollo/client"
import ToolTip from "../components/Tooltip"
import { Alert } from "@material-ui/lab"


// let EXPS = conts.exp.map( el => ({...el, name: decodeURI(el.name), str: decodeURI(el.str)}))
// let DESTS = conts.dest.map( el => ({...el, name: decodeURI(el.name), str: decodeURI(el.str)}))

export default function Home(props) {

    const { data, loading, error, refetch } = useQuery(QUERY_ALL_DATA)
    
    const {getAllData} = data || {}
    const oneItem = useQuery(QUERY_ALL_DATA)
    const [curIndex, setCurIndex] = useState()
    const [curItem, setCurItem] = useState()
    const [anchorEl, setAnchorEl] = useState(null)
    const [topAnchorEl, setTopAnchorEl] = useState(null)
    const [editModal, setEditModal] = useState()
    const [plicModal, setPlicModal] = useState()
    const [arModal, setArModal] = useState()
    const [batchPrint, setBatchPrint] = useState()
    const client = useApolloClient()
    const [destMutation] = useMutation(DEST_MUTATION)
    const [rmContact] = useMutation(CONTACT_RM_MUTATION)

    const clickHandler = row => () => {
        destMutation({ variables: { id: row.id + "" } }).then(
             r=> {refetch()},
             err => console.log("err: ", err))
    }

    const removeHandler = (ev,row) =>  {
        console.log(row.id)
        destMutation({variables: {id: row.id}}).then(
            r=>{refetch()},
            err => console.log("err", err)
        )
    }

    const isRowSelected = (dests, row) => {
        return (row && dests && dests.filter( el => el.id === row.id ) >= 0) ? false : true
    }

    const menuOpenHandler = row => ev => {
        setCurIndex(row.tableData.id)
        setCurItem({...row})
        setAnchorEl(ev.currentTarget)
        
    }
    const menuCloseHandler = () => {
        setCurIndex();setAnchorEl(null)
    }

    const addContactHandler = () => {
        setEditModal({id: null})
    }

    const closeEditModalHandler = ev =>{
        console.log("closing...", ev)
        setEditModal();setCurIndex();setCurItem();setAnchorEl(null)
        if ( ev ) { refetch() }
    }

    const topMenuHandler = () => {

    }

    const rmContactHandler = id => () => {
        rmContact({variables: {id: id}}).then( 
            r => {console.log("removed...", r); refetch()},
            err=> console.log("err remove contact", err)
        )
    }

    const resetHandler = () => {
        client.cache.writeQuery({query: DESTS, data: {Destinatar:[]}})
        refetch()
    }
    return (
        <div>
            <Layout>
                {/* {console.log("error:", error)} */}
                {error 
                    && <Error title = "EROARE">
                        {error.toString()}
                    </Error>
                }
                <Container title=" CORESPONDENȚĂ" >
                    {data && getAllData
                        ? (
                            <Grid container spacing={1}>
                                {getAllData.Contact.length === 0 && (
                                    <Grid item xs = {12} sm ={12}>
                                        <Alert severity="error" icon = {<FaExclamationTriangle size="3em"/>}>
                                            <div>
                                                <Typography variant = "h5">
                                            Se pare că folosiți aplicația pentru prima dată! 
                                            Pentru a înțelege mai bine cum funcționează, vă recomandăm
                                             să importați un set de date demonstrative?<br/>
                                             Le veți putea elimina ulterior din meniul Setări / Resetare
                                            </Typography>
                                            <div align="right">
                                            <Button 
                                                size="large" color = "secondary" variant="contained"
                                                onClick = {()=>props.history.push('/setari/restore/demo')}>DA, DORESC!</Button>
                                            </div>
                                            </div>
                                        </Alert>
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={12}>
                                    <RichCard 
                                        contentStyle = {{overflow: "hidden", height: "12vh"}}
                                    >
                                        {/* <Grid container spacing={1}>
                                            <Grid item xs = {12}> */}
                                                <Expeditor data = {getAllData.Expeditor || {}} onChange = {()=>refetch()}/>
                                            {/* </Grid>
                                        </Grid> */}

                                    </RichCard>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <RichCard 
                                    
                                        title = {`AGENDA (${ getAllData.Contact.length})`} contentStyle={{ height: "58vh", overflow: "hidden" }}
                                        action = {
                                            <FaPlusCircle 
                                                color = "inherit" size="1.4rem" style = {{marginTop: "5px"}} 
                                                onClick = {addContactHandler}
                                            />
                                        }
                                    >
                                        <MaterialTableInstance
                                            // onRowClick={clickHandler}
                                            options={{
                                                tableLayout: "auto", 
                                                rowStyle: row => {
                                                    return isRowSelected(getAllData.Destinatar,row) 
                                                        ? { background: "lightgrey"}
                                                        : { background: ""}
                                                },
                                                maxBodyHeight: "41vh",
                                               
                                                
                                            }}
                                            title={getAllData.Destinatar.length > 0 
                                                ? <Button color="secondary" onClick = {resetHandler}>Resetează selectia</Button> 
                                                : ""}
                                            columns={[
                                                { title: "Nr.crt", id: "nrcrt", render: row => row.tableData.id + 1, width: "5%" },
                                                { title: "Nume/Denumire", field: "name",  width: "30%",
                                                    render: row => <div style = {{display:"block", width: "100%"}}><small>{row.name}</small></div>,
                                                    cellStyle: row =>({wordWrap: "break-word", width: "30%"})
                                                },
                                                { title: "Localitate", field: "loc", width: "10%",
                                                cellStyle: row => ({wordWrap:"break-word", width: "10%"}),
                                                render: row => <small>{row.loc}</small>
                                                },
                                                {
                                                    title:"Actiuni", id: "act", align:"right", width: "30%",
                                                    render: row => (
                                                        <Fragment>
                                                            <Button size="small" onClick = {()=>setEditModal(row)}>
                                                                <small>Editeaza</small>
                                                            </Button>
                                                            <Button size="small" onClick = {rmContactHandler(row.id)}><small>Șterge</small></Button>
                                                            <Button size="small"  onClick = {clickHandler(row)}>
                                                                <Checkbox 
                                                                    size="small"
                                                                    checked = {isRowSelected(getAllData.Destinatar, row)} />
                                                                <small>SELECTAT</small>
                                                            </Button>
                                                        </Fragment>
                                                    )
                                                }
                                            ]}
                                            data={[...getAllData.Contact.map(e => ({ ...e }))]}
                                        />
                                    </RichCard>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <RichCard 
                                        title="SELECȚIE TIPĂRIRE"  contentStyle={{ height: "58vh", overflow:"hidden" }}
                                        action = {
                                        <Fragment>
                                            <ToolTip title = "PLICURI">
                                            <Fab size="small" variant="extended" color = "primary" onClick = {()=>setBatchPrint('plicuri')}>
                                                <Typography variant="h6"><strong>P</strong></Typography>                                                
                                            </Fab>
                                            </ToolTip>
                                            &nbsp;&nbsp;
                                            <ToolTip title = "CONFIRMĂRI DE PRIMIRE">
                                            <Fab size="small" variant="extended" color = "primary" onClick = {()=>setBatchPrint('confirmari')}>
                                                <Typography variant="h6"><strong>C</strong></Typography>
                                            </Fab>
                                            </ToolTip>
                                            &nbsp;&nbsp;
                                            <ToolTip title = "BORDEROU DE EXPEDIȚIE">
                                            <Fab size="small" variant="extended" color = "primary" onClick = {()=>setBatchPrint('borderou')}>
                                               <Typography variant="h6"><strong>B</strong></Typography>
                                            </Fab> 
                                            </ToolTip>
                                            {/* <IconButton 
                                            
                                                size = "small" color="inherit"
                                                onClick = {topMenuHandler}
                                                disabled = { getAllData.Destinatar.length === 0 }
                                                onClick = {ev => setTopAnchorEl(ev.currentTarget)}
                                            >
                                                <FaEllipsisV/>
                                            </IconButton> */}
                                        
                                        </Fragment>
                                        }
                                    >
                                        <MaterialTableInstance title={null} style = {{marginTop:"-50px"}}
                                            columns = {[
                                                {title: "#", id:"nrcrt", render:row=>row.tableData.id+1, width: "5%"},
                                                {title: "Destinatar(i)", field: "name"},
                                                {title: "Acțiuni", id: "acts", 
                                                    render: row => (
                                                            <IconButton size="small" onClick = {menuOpenHandler(row)}>
                                                                <FaEllipsisH/>
                                                            </IconButton>
                                                    )
                                                }
                                            ]}
                                            data = {[...getAllData.Destinatar.map(e=>({...e}))]}
                                            // onRowClick = {clickHandler}
                                            options= {{
                                                header:false, search:false,
                                                maxBodyHeight: "48vh",
                                                pageSize: 10,
                                            }}
                                            actions={[
                                                {
                                                    icon: ()=><FaTimesCircle size ="0.8rem" color="red"/>, 
                                                    tooltip: "save", 
                                                    onClick: removeHandler}
                                            ]}

                                        />
                                    </RichCard>
                                </Grid>
                            </Grid>
                        ) : <div>loading data...</div>
                    }

                </Container>
            </Layout>
            {anchorEl && (
                <Menu open = {Boolean(anchorEl)} anchorEl={anchorEl} onClose = {menuCloseHandler}>
                <MenuItem onClick = {() => setEditModal(curItem)} >
                        <FaPencilAlt/> &nbsp;&nbsp;
                        <small>Editează destinatarul</small>
                    </MenuItem>
                    <Divider/>
                    
                    <MenuItem onClick = {()=>setPlicModal({
                                                dest: curItem,
                                                exp: getAllData.Expeditor, 
                                                settings: getAllData.Setting
                    })}>
                        <FaEnvelopeOpenText/> &nbsp;&nbsp;
                        <small>Tipărește plicul</small>
                    </MenuItem>
                    <Divider/>
                    <MenuItem onClick = {()=>setArModal(curItem)}>
                        <FaDirections size="1.2rem" /> &nbsp;&nbsp;
                        <small>Tipărește confirmare</small>
                    </MenuItem>
                </Menu>
            )}
            {topAnchorEl && (
                <Menu open = {Boolean(topAnchorEl)} anchorEl={topAnchorEl} onClose = {()=>setTopAnchorEl(null)}>
                    <div align="center">
                    <Typography variant = "caption" color="inherit">
                        
                        TIPĂRIBILE MULTIPLĂ, DIN SELECȚIE
                    </Typography>
                    <Divider/>
                    </div>
                    <MenuItem onClick = {()=>setBatchPrint('plicuri')} style = {{color: "blue"}}>
                        <MdPrint size = "1.2rem"/>&nbsp;&nbsp;
                        <small>TIPĂRIRIE PLICURI</small>
                    </MenuItem>
                    <Divider/>
                    <MenuItem onClick = {()=>setBatchPrint('confirmari')} style = {{color: "red"}}>
                        <MdPrint size="1.2rem" /> &nbsp;&nbsp;
                        <small>TIPĂRIRE CONFIRMĂRI DE PRIMIRE</small>
                    </MenuItem>
                    <Divider/>
                    <MenuItem onClick = {()=>setBatchPrint('borderou')} style = {{color: "green"}}>
                        <MdPrint size="1.2rem" /> &nbsp;&nbsp;
                        <small>TIPĂRIRE BORDEROU DE EXPEDIȚIE</small>
                    </MenuItem>
                </Menu>
            )}

            { editModal && <ContactModal data = {editModal} onClose = {closeEditModalHandler} /> }
            { plicModal && <PlicModal data = {plicModal} onClose = {()=>setPlicModal(null)}/>}
            { arModal && <ARmodal data = {arModal} onClose = {()=>setArModal(null)}/>}
            { batchPrint && batchPrint === "plicuri" 
                && <BatchPlicuri 
                    data = {{
                        dest: getAllData.Destinatar, 
                        exp: getAllData.Expeditor, 
                        settings: getAllData.Setting
                    }}
                    onClose = {()=>setBatchPrint()} />
            }
            { batchPrint && batchPrint === "confirmari" 
                && <BatchConfirmari 
                        data = {{
                        dest: getAllData.Destinatar, 
                        exp: getAllData.Expeditor, 
                        settings: getAllData.Setting
                        }} 
                        onClose = {()=>setBatchPrint()} />
            }
            { batchPrint && batchPrint === "borderou" 
                && <BatchBorderou 
                data = {{
                dest: getAllData.Destinatar, 
                exp: getAllData.Expeditor, 
                settings: getAllData.Setting
                }} 
                onClose = {()=>setBatchPrint()} />
            }            
        </div>
    )
}

