import { BrowerRouter, BrowserRouter, Route, Switch } from 'react-router-dom';
import Plic from './routes/Plic';
import Home from './routes/Home';
import Borderou from './routes/Borderou';
import Confirmare from './routes/Confirmare';
import Error from './routes/Error';
import Landing from './routes/Landing';
import AddressBook from './routes/Plic/AddressBook';
import WithApollo from './apollo/client';
import Setari from './routes/Setari';
import Info from './routes/Info';


function App(props) {
  return (
    <div className="App">
      <WithApollo>
        <BrowserRouter>
      <Switch>
          <Route path = "/" exact component = {Home} />
          <Route path = "/plic/:data?" component = {Plic} />
          <Route path = "/borderou/:data?" component = {Borderou} />
          <Route path = "/confirmare/:data?" component = {Confirmare} />
          <Route path = "/agenda" component = {AddressBook} />
          <Route path = "/setari"  component = {Setari} />
          <Route path = "/info"  component = {Info} />
          <Route path = "/error/:code?" component = {Error} />
          <Route component = {Error}/>
      </Switch>
      </BrowserRouter>
      </WithApollo>
    </div>
  );
}

export default App;
