import config from './config.json'
import contacts from './contacts.json'
import complet from './complet.json'

const {Expeditor, Destinatar, Contact} = complet

const TESTDATA = {
    ...config,
    Contact: Contact,
    Expeditor: Expeditor,
    Destinatar: Destinatar
   
}

export default TESTDATA