
import React, {useEffect, useState} from 'react'
import {Snackbar} from "@material-ui/core"
import {Alert} from '@material-ui/lab'

export default function Notification(props) {
    
    const [success, setSuccess] = useState(false)

    useEffect(() => {
    //    console.log("eff:", props.show)
        setSuccess(Boolean(props.show))
    }, [props])

    const closeHandler = () => props.onClose(true)

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={success}
            autoHideDuration={2000}
            onClose={closeHandler}>
            <Alert severity={props.severity||"success"}>
                {props.show ? props.show : "OPERAȚIUNE FINALIZATĂ CU SUCCES!"
                }
            </Alert>
        </Snackbar>
    )
}




