import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, makeStyles } from '@material-ui/core'
import React, { Fragment, useState, useRef } from 'react'
import { FaFilePdf, FaTimesCircle } from 'react-icons/fa'
import styled from 'styled-components'
import { useReactToPrint } from 'react-to-print'
import BorderouTpl from '../PrintTemplates/BorderouTpl'


const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minWidth: "95vw", minHeight: "95vh"
    }
}))

const chunkify = (length, segment) => {
    let res = []
    for ( let i=0; i<length-1; i=i+segment ) {
        res.push(i)
    }
    return res
}



export default function BatchBorderou(props) {
    const printRef = useRef()
    const classes = useStyles()
    const { data } = props || {}
    const { exp, dest, header } = data || {}
    const [pageSize, setPageSize] = useState(5)
    const closeHandler = () => props.onClose(null)

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });


    return (
        <Dialog open={Boolean(data)} onClose={closeHandler} classes={{ paper: classes.dialogPaper }}>
            <DialogTitle>
                <Grid container justifyContent="space-between" alignItems="center">
                    TIPĂRIRE BORDEROU DE EXPEDIȚIE PENTRU DESTINATARII SELECTAȚI
                    <IconButton size="small" onClick={closeHandler}>
                        <FaTimesCircle color="lightgrey" size="1.4rem" />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Fragment>
                    <div ref={printRef}>
                        { chunkify([...data.dest].length, 10).map( (chunk, index) => (
                            <Fragment key = {index}>
                                 <Styles>
                                         <div className="landscape">
                                    <BorderouTpl exp ={data.exp} dest = {[...data.dest].slice(index, 10)} page = {index+1} className = "container"/>
                                    <div className="page-break">
                                            &copy;2021 -  Document generat prin intermediul aplicației online PLIX
                                    </div>
                                    </div>
                                </Styles>
                                
                            </Fragment>
                        ))}
                           

                       
                       

                    </div>
                    {/* {props && <pre>{JSON.stringify(props, null, 4)}</pre>} */}
                </Fragment>

            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={closeHandler}>RENUNȚĂ</Button>
                <Button variant="contained" color="primary" onClick={printHandler}>PRINT</Button>
            </DialogActions>

        </Dialog>
    )
}


const Styles = styled.div`{
@media screen {
    .container { zoom: 0.6 }
    .page-break {visibility:hidden}
}
@media print {


    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
      -webkit-filter:opacity(1);
      top:0;left:0;
    }
    @page {
        // size: 297mm 210mm!important;
        // margin: 20mm 10mm 10mm 10mm!important;
        
    }

    .landscape {
        width: 276mm;
        height: 190mm;
        transform: rotate(270deg) translate(-276mm, 0);
        transform-origin: 0 0;
      }
    .page-break {
        margin-top: 1;
        height: "10px";
        background: rgba(0,0,0,0.05);
        display: block;
        opacity: 0.3;
        padding-left: 10px;
        page-break-after: always;
    }
}
@media all {
   
}


`