import React, {useState, useEffect, useCallback} from 'react';
import ReactDOM from 'react-dom';
import {
    ApolloClient,
    ApolloProvider,
  } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/core';
import { persistCache } from 'apollo3-cache-persist';
import config from '../config';
import {  CONTACTS, DESTS, DEST_MUTATION, EXP, QUERY_ALL_DATA, SETTING } from './resolvers';
import { initialData } from './initialData';
import { schema } from './schema';
import { resolvers } from './resolvers';
import {decoder} from '../lib/uriDecode'
import * as localforage from 'localforage'

import {tweakedLocalStorage} from './tweakedLocalStorage'



const contactInterface = {id:"", name:"", str:"", nr:"", bl:"", sc:"", et:"", ap:"",
 jud:"", sect:"", loc:"", com:"", cp:"", tara:"" }

 function WithApollo(props) {

  const [client, setClient] = React.useState(null)
  const [loaded, setLoaded] = React.useState(false)
  const cache = new InMemoryCache({resultCacheMaxSize: 1000000})

//   localforage.config({
//     driver      : localforage.INDEXEDDB, 
//     name        : 'twixplix',
//     version     : 1.0,
//     storeName   : 'db', // Should be alphanumeric, with underscores.
//     description : 'plix db'
// })

  const clientInstance = new ApolloClient({
      uri: config.url,
      cache: cache,
    //   typeDefs: schema,
      resolvers: resolvers
  });




  React.useEffect(() => {
      persistCache({
          key: "persist",
          cache,
          storage: tweakedLocalStorage,
          // storage: localforage,
          maxSize: 31457280
      })
          .then((ls) => {
            setLoaded(true);
            setClient(clientInstance)
          })
        //   setLoaded(true);
        //   setClient(clientInstance)
  }, [])


  React.useEffect(() => {

        // const data = clientInstance.readQuery({ query:QUERY_ALL_DATA });
        // console.log("client data:", data)
    //     if (!data) {
    //       cache.writeQuery({
    //           query: DESTS,
    //           data: {Destinatar: []}
    //       })
    //       cache.writeQuery({
    //           query: CONTACTS,
    //           data: {Contact:[]}
    //       })
    //       cache.writeQuery({
    //           query: EXP,
    //           data: {Expeditor: []}
    //       })
    //       cache.writeQuery({
    //           query: SETTING, 
    //           data: {Setting: []}
    //       })
  
    //   }
     
  }, [ loaded ])

  return (
      <React.Fragment>
          {  client && loaded ? (
              <ApolloProvider client={client}>
                  {props.children}
              </ApolloProvider>
          ) : <div>Se așteaptă încărcarea memoriei cache...</div>}
      </React.Fragment>
  );
}

export default WithApollo;