import React, {useState, useEffect} from 'react'
import {Autocomplete} from '@material-ui/lab'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'

import { CONTACT_NAME_LOOKUP, EXPEDITOR_MUTATION } from '../apollo/resolvers'
import { makeStyles, TextField } from '@material-ui/core'
import FunkyInput from './FunkyInput'
import { useDebounce } from '../lib/hooks'
import InlineContact from './InlineContact'

const useStyles = makeStyles(theme => ({
    autoc: {
        fontSize: "1rem", fontWeight:600, color: theme.palette.primary.dark    }
})) 
export default function Expeditor(props) {
    const classes = useStyles()
    const [item, setItem] = useState()
    const [srcString, setSrcString] = useState("")
    const debounced = useDebounce(srcString, 300)
    useEffect( () => setItem(props.data), [props])

    

    const {data, loading, error, refetch} = useQuery(
         CONTACT_NAME_LOOKUP,
        {variables: {srcString: debounced || {}}
    })

    const [mutateExpeditor, _] = useMutation(EXPEDITOR_MUTATION)

   const inputHandler = (ev, newValue) => {
        setSrcString(newValue)
        
    }
    const changeHandler = async (ev, val, reason) => {
        let res;
        let elem;
        if ( reason === "clear" ) { elem = {name:""} }
        if ( reason = "select-option") { elem = val  }
        try {
            res = await mutateExpeditor({variables: {data: elem||{name:""}}})
        } catch (error) {
            console.log("error", error)
        }
           finally {
                refetch()
                setItem(res.contactNameLookup)
                setTimeout( () =>props.onChange(true), 300)
           }
        }
        

    return (
        <div>
            <Autocomplete 
            id ="acplt"
            size="small"
            placeholder = "ALEGEȚI UN EXPEDITOR"
            options = { data 
                && data.contactNameLookup
                && data.contactNameLookup.length > 0 
                    ? data.contactNameLookup 
                    : [item]
            }
            getOptionLabel={(option) =>(option && option.name) || "" }
            classes = {{"input":classes.autoc}}
            value = {(item && item[0])|| ""}
            renderInput={(params) => <FunkyInput type="select"
             {...params} label="E X P E D I T O R" variant="outlined" fullWidth={true} />}
            onInputChange = {inputHandler}
            inputValue={srcString}
            onChange = {changeHandler} 
        />  
        
        <InlineContact data = {item &&item[0] || null} />
    
            

            
            

        </div>
    )
}
