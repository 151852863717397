import MaterialTable from 'material-table'
import React, {forwardRef} from 'react'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };



export default function MaterialTableInstance(props) {
    return (
        <MaterialTable 
            icons={tableIcons}
            localization={{
                pagination: {
                    labelDisplayedRows: '{from}-{to} din {count}',
                    labelRowsPerPage: "rânduri",
                    labelRowsSelect: "rânduri"
                },
                toolbar: {
                    nRowsSelected: '{0} rânduri selectate',
                    searchPlaceholder: "caută"
                },
                header: {
                    actions: 'Acțiuni'
                },
                body: {
                    emptyDataSourceMessage: 'Nu sunt date',
                    filterRow: {
                        filterTooltip: 'Filtrează'
                    }
                },
               
            }}
            {...props}
            options={{
                filtering: props.filtering || false,
                selection: false,

                headerStyle: {
                    padding: "5px",
                    // border: "1px solid black",
                    lineHeight: 1.2,
                    // minHeight: "30px",
                    backgroundColor: 'aliceblue',
                    color: 'navy',
                    fontSize: "0.8rem",
                    
                },
                cellStyle: { padding: "0 5px 0 5px", fontSize: "0.8rem", color: "navy" },
                rowStyle: {
                    // height: "40px"
                },
                maxBodyHeight: "28vh",
                
                pageSize: 5,
                pageSizeOptions: [5,9,10,12,20,50,100,1000],
                grouping:false,
                ...props.options
            }}
           
            
        />
    )
}