const iso = [
{
        group: "plic",
        name: "DL",
        label: "PLIC DL",
        desc: "plic format DL (lung)",
        key: "style",
        value: { width: 220, height: 110 }
    },
{
        
        group: "plic",
        name: "B4",
        label: "PLIC B4",
        desc: "plic format B4",
        key: "style",
        value: { width: 353, height: 250 }
    },
{
        
        group: "plic",
        name: "B5",
        label: "PLIC B5",
        desc: "plic format B5",
        key: "style",
        value: { width: 250, height: 176 }
    },
{
        
        group: "plic",
        name: "B6",
        label: "PLIC B6",
        desc: "plic format B6",
        key: "style",
        value: { width: 176, height: 125 }
    },
{
        
        group: "plic",
        name: "C3",
        label: "PLIC C3",
        desc: "plic format C3",
        key: "style",
        value: { width: 458, height: 324 }
    },
{
        
        group: "plic",
        name: "C4",
        label: "PLIC C4",
        desc: "plic format C4",
        key: "style",
        value: { width: 324, height: 229 }
    },
{
        
        group: "plic",
        name: "C4M",
        label: "PLIC C4M",
        desc: "plic format C4M",
        key: "style",
        value: { width: 318, height: 229 }
    },
{
        
        group: "plic",
        name: "C5",
        label: "PLIC C5",
        desc: "plic format C5",
        key: "style",
        value: { width: 229, height: 162 }
    },    
{
        
        group: "plic",
        name: "C6/C5",
        label: "PLIC C6/C5",
        desc: "plic format C6/C5",
        key: "style",
        value: { width: 229, height: 114 }
    },
{
        
        group: "plic",
        name: "C6",
        label: "PLIC C6",
        desc: "plic format C6",
        key: "style",
        value: { width: 162, height: 114 }
    },
{
        
        group: "plic",
        name: "C64M",
        label: "PLIC C64M",
        desc: "plic format C64M",
        key: "style",
        value: { width: 318, height: 114 }
    },
{
        
        group: "plic",
        name: "C7/C6",
        label: "PLIC C7/C6",
        desc: "plic format C7/C6",
        key: "style",
        value: { width: 162, height: 81 }
    },
{
        
        group: "plic",
        name: "C7",
        label: "PLIC C7",
        desc: "plic format C7",
        key: "style",
        value: { width: 114, height: 81 }
    },
{
        
        group: "plic",
        name: "CE4",
        label: "PLIC CE4",
        desc: "plic format CE4",
        key: "style",
        value: { width: 310, height: 229 }
    },
{
        
        group: "plic",
        name: "CE64",
        label: "PLIC CE64",
        desc: "plic format CE64",
        key: "style",
        value: { width: 310, height: 114 }
    },
{
        
        group: "plic",
        name: "E4",
        label: "PLIC E4",
        desc: "plic format E4",
        key: "style",
        value: { width: 312, height: 220 }
    },
{
        
        group: "plic",
        name: "EC45",
        label: "PLIC EC45",
        desc: "plic format EC45",
        key: "style",
        value: { width: 229, height: 220 }
    },
{
        
        group: "plic",
        name: "EC5",
        label: "PLIC EC5",
        desc: "plic format EC5",
        key: "style",
        value: { width: 229, height: 155 }
    },
{
        
        group: "plic",
        name: "E5",
        label: "PLIC E5",
        desc: "plic format E5",
        key: "style",
        value: { width: 229, height: 115 }
    },
{
        
        group: "plic",
        name: "E56",
        label: "PLIC E56",
        desc: "plic format E56",
        key: "style",
        value: { width: 155, height: 155 }
    },
{
        
        group: "plic",
        name: "E6",
        label: "PLIC E6",
        desc: "plic format E6",
        key: "style",
        value: { width: 110, height: 155 }
    },
{
        
        group: "plic",
        name: "E65",
        label: "PLIC E65",
        desc: "plic format E65",
        key: "style",
        value: { width: 110, height: 220 }
    },
{
        
        group: "plic",
        name: "R7",
        label: "PLIC R7",
        desc: "plic format R7",
        key: "style",
        value: { width: 120, height: 135 }
    },
{
        
        group: "plic",
        name: "S4",
        label: "PLIC S4",
        desc: "plic format S4",
        key: "style",
        value: { width: 250, height: 330 }
    },
{
        
        group: "plic",
        name: "S5",
        label: "PLIC S5",
        desc: "plic format S5",
        key: "style",
        value: { width: 185, height: 255 }
    },
{
        
        group: "plic",
        name: "S65",
        label: "PLIC S65",
        desc: "plic format S65",
        key: "style",
        value: { width: 110, height: 225 }
    },
{
        
        group: "plic",
        name: "XS",
        label: "PLIC XS",
        desc: "plic format XS",
        key: "style",
        value: { width: 105, height: 216 }
    },
{
        
        group: "plic",
        name: "EXS",
        label: "PLIC EXS",
        desc: "plic format EXS",
        key: "style",
        value: { width: 155, height: 216 }
    },
]

export default iso