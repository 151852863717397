import { Button, Checkbox, Divider, FormControlLabel, List, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { Fragment } from 'react'
import RichCard from '../../../components/RichCard'
import { useMutation, useQuery } from '@apollo/client'
import { QUERY_ALL_DATA, IMPORT_ALL_MUTATION } from '../../../apollo/resolvers'
import Notification from "../../../components/Notification"
import TESTDATA from '../../../assets/testData'
import { useParams } from 'react-router-dom'

const urlDecoder = (arr) => {
    return [...arr].map(item => {
        let keys = Object.keys(item)
        keys.map(key => item[key] = decodeURI(item[key]))
        return item
    })
}

const CONTACT_KEYS = ["ap","bl","com","cp","et","jud","loc","name","nr","sc","sect","str","tara"]

const normalizeContact = obj => {
    return CONTACT_KEYS.reduce( (acc, key) => {
        let el = obj[key] ? obj[key]: ""
        return {...acc, [key]: el}
    }, {})
}

const useStyles = makeStyles(theme => ({
    label: {
        display: "inline-block",
        backgroundColor: "navy",
        color: "white",
        padding: "0.5rem",
        fontFamily: "sans-serif",
        borderRadius: "0.3rem",
        cursor: "pointer",
        width: "100%",
        marginTop: "1rem", textAlign: "center", borderRadius: "1em"
    },
    loading: {
        position: "fixed",
        marginTop: "auto",
        marginBottom: "auto",
        marginLeft: "25%",
        marginRight: "25%",
        width: "70vw",
        zIndex: 99999,
        background: "none", color: "red", fontSize: "6em", textShadow: "2px 2px 3px black"
    }
}))

export default function Restore(props) {
    const classes = useStyles()
    const [data, setData] = useState()
    const [notify, setNotify] = useState()
    const [importAllMutation, dispatch] = useMutation(IMPORT_ALL_MUTATION)
    const [selected, setSelected] = useState({
        Contact: false,
        Setting: false,
        Expeditor: false,
        Destinatar: false
    })
    const q = useQuery(QUERY_ALL_DATA)
    const params = useParams()

    useEffect(()=>{
        console.log("props:", params)
        if ( params.demo ) {
            setData(TESTDATA)
        }
    }, [props])

    const selectAllHandler = name => ev => {
        ev.preventDefault()
        setSelected({ Contact: name, Setting: name, Expeditor: name, Destinatar: name})
    }

    const jsonUploadHandler = (event) => {
        let f = event.target.files[0];
        var name = f.name;
        const reader = new FileReader();
        reader.onload = (evt) => {
            let data = evt.target.result;
            data = (JSON.stringify(eval('(' + data + ')')));
            data = JSON.parse(data);
            // decode uri encoded values of dest end exp
            console.log("uploaded:", data)
            if ( data.dest ) {
                data.Contact = data.dest ? urlDecoder(data.dest).map( e => normalizeContact(e)) : null
            } else {
                data.Contact = data.Contact ? urlDecoder(data.Contact) : null
            }
            data.exp = data.exp ? urlDecoder(data.exp) : null
            data.Setting = data.Setting ? data.Setting : null
            setData(data)

        };
        reader.readAsBinaryString(f);
    }

    const resetHandler = () => setData()

    const importHandler = () => {
        const selectedItems = Object.keys(selected).reduce( (acc,key) => {
            if ( selected[key] ) {
                acc = {...acc, [key]: data[key]}
                return acc
            } else { return acc }
        }, {})
        importAllMutation({variables: {data: selectedItems}}).then( 
            r => {
               q.refetch().then( () =>  setNotify(r.data.importAllMutation.toString()) )

              
                
                
            },
            err => setNotify("A APARUT O EROARE " + err.toString)
            )

    }

    const loadTestData = () => setData(TESTDATA)


    const toggleHandler = name => ev => setSelected({...selected, [name]: ev.target.checked})

    return (
        <Fragment>
            {dispatch && dispatch.error && <pre>{dispatch.errror.toString()}</pre>}


            {/* {console.log("dispatch: ", dispatch )} */}
            <RichCard
                title="RESTAURARE DATE DIN BACKUP EXISTENT" contentStyle={{ height: "65vh" }} style={{ height: "70vh" }}
                action={data && (
                    <Fragment>
                        <Button color="secondary" variant="contained" size="small" onClick={resetHandler}>RESET</Button>
                        &nbsp;&nbsp;
                        <Button color="primary" variant="contained" size="small" onClick={importHandler}>IMPORTĂ DATELE</Button>

                    </Fragment>
                )}
            >
                {!data
                    ? (
                        <Fragment>
                            <input type="file" id="upload" hidden onChange={jsonUploadHandler} />
                            <label htmlFor="upload" className={classes.label}>
                                <div>
                                    <div style={{ fontSize: "4em", fontWeight: 800 }}>ÎNCARCĂ UN FIȘIER DE BACKUP</div><br />
                                    <div>(se acceptă și fișiere de backup din versiunea anterioară)</div>
                                </div>
                            </label>
                            <br/><br/>
                            <Button variant = "contained" size="large" fullWidth color="secondary" onClick={loadTestData}>
                            <div>
                                <Typography variant = "h4" color = "inherit">
                                INCARCĂ UN SET DE DATE DEMO 
                                </Typography>
                                <Typography variant = "overline">
                                    setul de date demonstrative pe care vi-l punem la dispoziție {' '}
                                </Typography>
                                <Typography variant = "overline">
                                    include câteva instituții din județul Arad,{' '}
                                </Typography>
                                <Typography variant = "overline">
                                     precum și cele mai uzuale modele de plicuri, ca bază creativă de pornire.
                                </Typography>
                            </div>
                            
                            </Button>
                         
                        </Fragment>
                    )
                    : (
                        <Fragment>
                            {data && (
                                <Fragment>
                                            <div align="right">
                                        {Object.keys(selected).reduce((a,e,i)=>{
                                             if (selected[e]) {a=a+1}
                                             return a
                                             } ,0) > 0 
                                                ? <FormControlLabel
                                                    control = {<Checkbox  size="small" checked = {true} onClick = {selectAllHandler(false)}/>}
                                                    label = {<small>DESELECTEAZĂ-LE PE TOATE</small>} />
                                                : <FormControlLabel
                                                    control = {<Checkbox size="small" checked = {false} onClick = {selectAllHandler(true)}/>}
                                                    label = {<small>SELECTEAZĂ-LE PE TOATE</small>} />
                                             
                                            }</div>

                                    <List>
                                       
                                        <ListItem>
                                            <ListItemIcon>
                                                    <Checkbox checked={selected.Contact} onChange = {toggleHandler('Contact')}/>
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="h5" color="primary">
                                                Contacte ({data.Contact && data.Contact.length})
                                                </Typography>
                                            </ListItemText>

                                        </ListItem>
                                        <Divider />
                                        <ListItem>
                                            <ListItemIcon>
                                                <Checkbox checked={selected.Setting}  onChange = {toggleHandler('Setting')}/>
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="h5" color="primary">
                                                    Setări ({data.Setting ? data.Setting.length : 0})
                                                </Typography>
                                            </ListItemText>

                                        </ListItem>
                                        <Divider />
                                        <ListItem>
                                            <ListItemIcon>
                                                <Checkbox checked={selected.Destinatar} onChange = {toggleHandler('Destinatar')}/>
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="h5" color="primary">
                                                    Destinatari ({data.Destinatar ? data.Destinatar.length : 0})
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <Divider />
                                        <ListItem>
                                            <ListItemIcon>
                                                <Checkbox checked={selected.Expeditor} onChange = {toggleHandler('Expeditor')}/>
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="h5" color="primary">
                                                    Expeditori ({data.Expeditor ? data.Expeditor.length : 0})
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <Divider />
                                        <Divider />

                                    </List>
                                    <Button fullWidth variant="contained" color="secondary" size="large" onClick = {importHandler}>
                                        <Typography variant="h4">IMPORTĂ ELEMENTELE SELECTATE</Typography>
                                    </Button>
                                </Fragment>
                            )}
                            {/* {data && data.Setting && <pre>{JSON.stringify(data.Setting, null, 2)}</pre>} */}
                        </Fragment>
                    )

                }



            </RichCard>
          {notify &&  <Notification show = {notify} onClose = {()=>setNotify()} />}
        </Fragment> 

    )
}
