import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {QUERY_ALL_DATA, RESET_MUTATION} from '../../../apollo/resolvers'
import { Fragment } from 'react'
import RichCard from '../../../components/RichCard'
import { Button, Checkbox, Divider, FormControlLabel, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'

export default function Reset(props) {
    const {data, loading, error, refetch} = useQuery(QUERY_ALL_DATA)

    const [selected, setSelected] = useState({
        Contact: false,
        Expeditor: false,
        Destinatar: false,
        Setting: false
    })
    const [rstMutation, dispatch] = useMutation(RESET_MUTATION)

    const toggleHandler = name => ev => setSelected({...selected, [name]: ev.target.checked})

    const selectAllHandler = name => ev => {
        ev.preventDefault()
        setSelected({ Contact: name, Setting: name, Expeditor: name, Destinatar: name})
    }



    const resetHandler = () => {
        rstMutation({variables: {data: selected}}).then( r => {
            setSelected({Contact:false, Expeditor: false, Destinatar:false, Setting: false})
        }, err=> console.log("errr:", err))
        
       
    }

    const areAllSelected = () =>  Object.keys(selected).reduce( (a,e) => selected[e] ? a+1:a ,0)
        
        
    
    return (
        <Fragment>

             <RichCard
            title="RESETARE CONȚINUT" contentStyle={{ height: "65vh" }} style={{ height: "70vh" }}
            >
                {data && (
                    <Fragment>
                        <div align="right">
                        {areAllSelected() > 0 
                        ? <FormControlLabel 
                            control = {<Checkbox size="small" checked = {true} onClick = {selectAllHandler(false)} /> } 
                            label = {<small>DESELECTEAZĂ-LE PE TOATE</small>}
                        />
                        : <FormControlLabel 
                            control = {<Checkbox size="small" checked = {false} onClick = {selectAllHandler(true)} /> } 
                            label = {<small>SELECTEAZĂ-LE PE TOATE</small>}
                        />
                        }
                        </div>
                    <List>
                        <ListItem>
                            <ListItemText>
                                Resetare contacte ({data.getAllData.Contact.length})
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <Checkbox checked = {selected.Contact} onChange = {toggleHandler('Contact')}/>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider/>
                        <ListItem>
                            <ListItemText>
                                Resetare destinatari ({data.getAllData.Destinatar.length})
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <Checkbox checked = {selected.Destinatar} onChange = {toggleHandler('Destinatar')}/>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider/>
                        <ListItem>
                            <ListItemText>
                                Resetare expeditor ({data.getAllData.Expeditor.length})
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <Checkbox checked = {selected.Expeditor} onChange = {toggleHandler('Expeditor')}/>
                            </ListItemSecondaryAction>

                        </ListItem>
                        <Divider/>
                        <ListItem>
                            <ListItemText>
                                Resetare configurări ({data.getAllData.Setting.length})
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <Checkbox checked = {selected.Setting} onChange = {toggleHandler('Setting')}/>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider/>

                    </List>
                    <Button fullWidth variant="contained" size="large" color="secondary" onClick = {resetHandler}>APLICĂ</Button>
                    </Fragment>
                )}

            </RichCard>
    </Fragment>)

}