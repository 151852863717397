import React from 'react'
import Container from '../../../components/Container'
import Layout from '../../../components/Layout'

export default function AddressBook(props) {
    return (
       <Layout>
           <Container title = "AGENDA APLICAȚIEI">
               bla
           </Container>
       </Layout>
    )
}
