import { useMutation } from '@apollo/client'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { CONTACT_MUTATION } from '../../apollo/resolvers'
import FunkyInput from "../FunkyInput"

const CONTACT_KEYS = ["ap","bl","com","cp","et","id","jud","loc","name","nr","sc","sect","str","tara"]

const normalizeContact = obj => {
    return CONTACT_KEYS.reduce( (acc, key) => {
        let el = obj[key] ? obj[key]: ""
        return {...acc, [key]: el}
    }, {})
}

const FIELDS = [
    {key: "name", label: "NUME/DENUMIRE", xs:12, sm:12},
    {key: "str", label: "STRADA", xs:12, sm:12, align:"left"},
    {key: "nr", label: "NR.", xs:12, sm:3},
    {key: "bl", label: "BLOC", xs:12, sm:2},
    {key: "sc", label: "SCARA", xs:12, sm:2},
    {key: "et", label: "ETAJ", xs:12, sm:2},
    {key: "ap", label: "AP.", xs:12, sm:3},
    {key: "loc", label: "LOCALITATE", xs:12, sm:5},
    {key: "sect", label: "SECTOR", xs:12, sm:3},
    {key: "jud", label: "JUDEȚ", xs:12, sm:4},
    {key: "cp", label: "COD POȘTAL", xs:12, sm:3},
    {key: "tara", label: "ȚARA", xs:12, sm:9},
]
export default function ContactModal(props) {
    const [item, setItem ] = useState()
    useEffect(() => setItem(props.data), [props])
    const [mutateContact] = useMutation(CONTACT_MUTATION)
    
    const inputHandler = name => ev => setItem({...item, [name]: ev.target.value})

    const closeHandler = () => props.onClose(null)

    const submitHandler = () => {

        mutateContact({variables: {data: normalizeContact(item)}}).then( 
            r => props.onClose(r),
            err =>{console.log("err:", err); props.onClose(null)}
        )
    }

    return (
       <Dialog open = {Boolean(item)} onClose = {closeHandler}>
           <DialogTitle>
                {(item && item.id) ? "EDITARE ": "ADĂGARE "}
               CONTACT
            </DialogTitle>
           <DialogContent>
               {item && (
                   <Fragment>
                       <Grid container spacing={1}>
                       {FIELDS.map( (e,i) => (
                           <Grid item key={i} xs = {e.xs} sm={e.sm}>
                               <FunkyInput 
                                    value = {item[e.key] || ""} 
                                    label={e.label}
                                    onChange = {inputHandler(e.key)}
                               />
                            </Grid>
                       ))}
                       </Grid>
                   </Fragment>
               )}
           </DialogContent>
           <DialogActions>
               <Button color="secondary" onClick ={closeHandler}>RENUNȚĂ</Button>
               <Button color="primary" onClick ={submitHandler}>CONFIRMĂ</Button>
           </DialogActions>
       </Dialog>
    )
}
