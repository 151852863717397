import { makeStyles, Toolbar, Typography, 
    // useMediaQuery, 
    // useTheme, 
    Card, CardContent, Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        // padding: "10px",

    },
    toolbar: {
        background: "white",
        display: "flex", alignItems: "center", justifyContent: "space-between",
        paddingRight: 0, paddingLeft: theme.spacing(1),
    },
    toolbarPaper: { borderBox: "5px 5px 10px red"},
    content: {
        height: "80vh", padding: theme.spacing(0.5), 
        overflowX: "hidden",
        background: "none",

    }
}))

const Container = ({title, action, children, style}) => {
    const classes = useStyles()
    // const theme = useTheme()
    // const width = useMediaQuery(theme.breakpoints.up('sm'))

    return (
        <Card className = {classes.root} style = {style}>
            <Toolbar variant = "dense" className = {classes.toolbar} classes = {{dense: classes.toolbarPaper}}>
                <Grid item xs>
                <Typography variant = "body1" color = "primary" component = "div"><strong>{title}</strong></Typography>
                </Grid>
                <Grid item xs align="right">{action}</Grid>
            </Toolbar>
            <CardContent className = {classes.content}>
                {children}
            </CardContent>
        </Card>
    )
}
export default Container