
import gql from "graphql-tag"
import { UUID } from "../lib/uuid"
import { decoder } from "../lib/uriDecode"
import { ApolloError } from "@apollo/client"

const contactInterface = {id: "", name:"", str:"", nr:"", bl:"", sc:"", et:"", ap:"",
 jud:"", sect:"", loc:"", com:"", cp:"", tara:"" }



export const QUERY_ALL_DATA = gql`
    query Query {
        getAllData @client {
            Contact {id name str nr bl sc et ap jud sect loc com cp tara  }
            Destinatar { id name str nr bl sc et ap jud sect loc com cp tara }
            Expeditor { id name str nr bl sc et ap jud sect loc com cp tara }
            Setting { id group name key value desc default}
        }
    }
`

export const CONTACTS =gql`
    query Contacts  {
        Contact @client {
            id name str nr bl sc et ap jud sect loc com cp tara  
        }
    }`


export const CONTACT_ID_LOOKUP =gql`
    query Contact($id:String)  {
        contact(id:$id) @client{
            id name str nr bl sc et ap jud sect loc com cp tara  
        }
    }`

export const CONTACT_NAME_LOOKUP = gql`
    query contactNameLookup($srcString: String) {
        contactNameLookup(srcString: $srcString) @client {
            id name str nr bl sc et ap jud sect loc com cp tara  
        }
        
    }
`

export const DESTS = gql`
    query Destinatar{
        Destinatar @client { id }
    }`

export const EXP = gql`
    query Expeditor{
        Expeditor @client { id name str nr bl sc et ap jud sect loc com cp tara  }
    }
`

export const SETTING = gql`
    query setting{
        Setting @client { id group name key value desc default}
    }
`

export const DEST_MUTATION = gql`
    mutation dest($id:String) {
        destMutatation(id:$id) @client { id }
    }
`
export const CONTACT_MUTATION = gql`
    mutation ctct($data: Contact) {
        contactMutation(data: $data) @client{
            id name str nr bl sc et ap jud sect loc com cp tara  
        }
    }
`

export const CONTACT_RM_MUTATION = gql`
    mutation rmContact($id: String) {
        contactRmMutation(id:$id) @client {
            id name str nr bl sc et ap jud sect loc com cp tara 
        }
    }
`

export const EXPEDITOR_MUTATION = gql`
    mutation expdt($data: Contact) {
        expeditorMutation(data:$data) @client{
            id name str nr bl sc et ap jud sect loc com cp tara  
        }
    }
`

export const SETTING_MUTATION = gql`
    mutation sm($data: Setting) {
        settingMutation(data: $data) @client {
            id group name key value desc default
        }
    }
`


export const SETTING_RM_MUTATION = gql`
    mutation sm($id: String) {
        settingRmMutation(id: $id) @client {
            id group name key value desc default
        }
    }
`

export const IMPORT_CONTACTS_MUTATION = gql`
    mutation icm($data: [Contact] $overwrite:Boolean ) {
        importContactMutation(data: $data, overwrite: $overwrite) @client {
            id group name key value desc default
        }
    }
`

export const IMPORT_ALL_MUTATION = gql`
    mutation importAll($data: ImportInput ) {
        importAllMutation(data: $data) @client {
            Boolean
        }
    }
`
export const RESET_MUTATION = gql`
    mutation rst($data: ResetInput) {
        resetMutation(data:$data) @client {
            Boolean
        }
    }

`

export const resolvers = {
    Mutation: {
        destMutatation: (_, args, {cache}) => {
            let {id} = args
            let {Destinatar} = cache.readQuery({query: DESTS}) || []
            Destinatar = Destinatar ? Destinatar.map(el => ({...el})) : []
            let idx = Destinatar.findIndex( el => el.id === id )
            if (idx >= 0) {
                Destinatar.splice(idx,1)
            } else {
                Destinatar.push({id:id})
            }
            cache.writeQuery({
                query: DESTS,
                data: {Destinatar:Destinatar}
            })
            return Destinatar
        },
        contactMutation: (_, {data}, {cache}) => {
            
            let {id, name, str, nr, bl, sc, et, ap, jud, sect, loc, com, cp, tara} = data
            let {Contact} = cache.readQuery({query: CONTACTS}) || []
            Contact = Contact.map(e=>({...e}))
            let ix = Contact.findIndex(el => el.id === id)
            if ( ix>=0 ) {
                Contact[ix] = {
                    id: id, name:name||"", str:str||"", nr:nr||"", bl:bl||"", sc:sc||"",
                     et:et||"", ap:ap||"", jud:jud||"", sect:sect||"", loc:loc||"", com:com||"", 
                    cp:cp||"", tara:tara||""
                }
            } else {
                Contact.push({id: UUID(), name:name||"", str:str||"", nr:nr||"", bl:bl||"", sc:sc||"",
                et:et||"", ap:ap||"", jud:jud||"", sect:sect||"", loc:loc||"", com:com||"", 
               cp:cp||"", tara:tara||""})
            }
            cache.writeQuery({
                query: CONTACTS,
                data: {Contact:Contact}
            })
            return Contact
            
        },
        contactRmMutation: (_,{id}, {cache}) => {
            let q = cache.readQuery({query: CONTACTS})
            q = q.Contact.map( el => ({...el}))
            let targetIdx = q.findIndex( el => el.id === id)
            if ( targetIdx >= 0 ) {
                q.splice(targetIdx, 1)
            }
            cache.writeQuery({
                query: CONTACTS,
                data: {Contact: q}  
            })
            return id
        },
        expeditorMutation: (_, {data}, {cache}) => {
            if ( data ) {
                cache.writeQuery({
                    query: EXP,
                    data: {Expeditor: [data]}
                })
            } else {
                cache.writeQuery({
                    query: EXP, data: {Expeditor: []}
                })
            }
            return data
        },
        settingMutation: (_, {data}, {cache}) => {
            if ( data ) {
                let q = cache.readQuery({query: SETTING})
                q = q || {Setting: []}
                q = q.Setting.map( el => ({...el}))
                let id =  data.id || null
                let uid = UUID()
                let item = q.find( elem => elem.id === id )
                if ( typeof item !== "undefined" ) {
                    q[q.indexOf(item)] = {group: "", label: "", desc: "", key: "", value: "", ...data}
                } else {
                    q = [
                        ...q,
                        {id: uid, group: "", label: "", desc: "", key: "", value: "", ...data }
                    ]
                }
                cache.writeQuery({
                    query: SETTING,
                    data: {Setting: q}
                })
                return data.id ? data : {id: uid, ...data}
            }
        },
        settingRmMutation: (_,{id},{cache}) => {
            let q = cache.readQuery({query: SETTING})

            q = q.Setting.map( el => ({...el}))
            let targetIdx = q.findIndex( el => el.id === id)
            if ( targetIdx >= 0 ) {
                q.splice(targetIdx, 1)
            }
            cache.writeQuery({
                query: SETTING,
                data: {Setting: q}  
            })
            return id
        },
        importContactMutation: (__, {data, overwrite=true}, {cache}) => {
            const q = cache.readQuery({query: QUERY_ALL_DATA})
            const { getAllData } = q || {}
            const {Contact} = getAllData || {}
            let res = overwrite ? [...Contact, ...data] : [...data]
            if ( res.length <= 3000 ) {
                cache.writeQuery({
                    query: CONTACTS,
                    data: {Contact:res.map( el => ({...contactInterface, ...decoder(el)}))}
                })
                return true
            } else {
                return new ApolloError("Ne pare rău, tehnologia INDEXEDDB nu poate stoca mai mult de 3000 de înregistrări")
            }
        },
        importAllMutation: (__, {data}, {cache}) => {
            const q = cache.readQuery({query: QUERY_ALL_DATA})
            const { getAllData } = q || {}
            const {Contact, Destinatar, Expeditor, Setting} = getAllData || {}
            // console.log(data)
            let res = {
                Contact: data.Contact ? [...Contact, ...data.Contact.map( el => ({...decoder(el)})) ] : (Contact||[]),
                Expeditor: data.Expeditor ? [...Expeditor, ...data.Expeditor.map( el => ({...decoder(el)})) ] : (Expeditor||[]),
                Destinatar: data.Destinatar ? [...Destinatar, ...data.Destinatar.map( el => ({...decoder(el)})) ] : (Destinatar||[]),
                Setting: data.Setting ? [...Setting, ...data.Setting.map( el => ({...el})) ] : (Setting||[])
            }

            if ( res.Contact.length+res.Expeditor.length+res.Destinatar.length+res.Setting.length <= 50000) {
                let contacts = res.Contact.map( e => ({...contactInterface, ...e, id: e.id ? e.id : UUID()}))
                // console.log("contacts: ", contacts)
                 cache.writeQuery({ query: CONTACTS, data: {
                     Contact: contacts }
                })
                 cache.writeQuery({ query: EXP, data: {Expeditor: res.Expeditor}})
                 cache.writeQuery({ query: DESTS, data: {Destinatar: res.Destinatar}})
                 cache.writeQuery({ query: SETTING, data: {Setting: res.Setting}})
                return "OPERATIUNE FINALIZATA CU SUCCES"

            } else {
                return new ApolloError({errorMessage: "max size exceeded"})
            }

        },
        resetMutation: (__, {data}, {cache}) => {
            const q = cache.readQuery({query: QUERY_ALL_DATA})
            const { getAllData } = q || {}
            const {Contact, Destinatar, Expeditor, Setting} = getAllData
            let res = {
                Contact: !!data.Contact ? [] : Contact,
                Destinatar: !!data.Destinatar ? [] : Destinatar,
                Expeditor: !!data.Expeditor ? [] : Expeditor,
                Setting: !!data.Setting ? [] : Setting
            }
            cache.evict({})
             cache.writeQuery({query: QUERY_ALL_DATA, data: {getAllData: res}})
            


        }
    },
    Query: {
        getAllData:  (_, __, {cache}) => {
            let {Contact} = cache.readQuery({query: CONTACTS}) || {Contact: []}
            let {Destinatar} = cache.readQuery({query: DESTS}) || {Destinatar: []}
            let {Expeditor} = cache.readQuery({query: EXP}) || {Expeditor: []}
            let {Setting} = cache.readQuery({query: SETTING}) || {Setting: []}

            return {
                Contact: Contact,
                Destinatar: Destinatar.reduce( (acc,e) => {
                    let index = Contact.findIndex(el => el.id === e.id )
                    if ( index>=0 ) acc.push(Contact[index])
                    return acc
                }, []),
                Expeditor: Expeditor,
                Setting: Setting
                
            }
        },
        contact: (_, args, {cache}) => {
            let res = cache.readQuery({query: CONTACTS})
            return res.Contact.find( el => el.id === args.id )
        },
        contactNameLookup: (_, {srcString}, {cache}) => {
            let res = cache.readQuery({query: CONTACTS}) || []
            srcString = srcString || "----"
            return res.Contact.filter( el => el.name && el.name.toUpperCase().includes(srcString.toUpperCase()) )
        }
       
    },
 
}

