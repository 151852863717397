import React from 'react'
import PlicTpl from '../../../components/PrintTemplates/PlicTpl'

export default function Preview({data, onTabChange, onChange}) {
    console.log("data:", data)
    return (
        <div style = {{ zoom: 0.6}}>
            <PlicTpl exp ={{name: "ion"}} dest = {{name: "gheorghe"}} tpl={{...data}} /> 
        </div>
    )
}
