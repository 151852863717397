import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles } from '@material-ui/core'
import React, {useState, useEffect, Fragment, useRef} from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import {useReactToPrint} from 'react-to-print'
import PlicTpl from '../PrintTemplates/PlicTpl'

const useStyles = makeStyles(theme =>({
    dialogPaper: {
        minWidth: "90vw", minHeight: "80vh"
    }
}) )

export default function PlicModal(props) {

    const printRef = useRef()
    const classes = useStyles()
    const [item, setItem ] = useState()
    const [tpl, setTpl] =  useState({})
    let data = props.data || {}
    useEffect( () => {
        if (data && data.settings) {
            let defaultTpl = data.settings.filter( el => !!el.default)
            defaultTpl = defaultTpl.length > 0 ? defaultTpl[0] : {}
            setTpl(defaultTpl)
        }
    }, [])

    useEffect(() => {
        setItem(props.data)
    }, [props])

    const closeHandler = item => () => {
        if ( !item ) { props.onClose(null)
        } else {
            props.onClose(item)
        }
    }

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });

    return (
        <Dialog open = {Boolean(item)} onClose = {closeHandler(null)} classes = {{paper: classes.dialogPaper}}>
            <DialogTitle >
                <Grid container justifyContent="space-between" alignItems="center">
                    TIPĂRIRE PLIC
                    <Grid item align="right">
                        <IconButton onClick = {closeHandler(null)}><FaTimesCircle color="lightgrey" size = "1em"/></IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                    {item &&( 
                        <Fragment>
                            <div ref = {printRef}>
                            <PlicTpl exp = {data.exp[0]} dest = {data.dest} tpl = {tpl} />
                            </div>
                        </Fragment>
                    )}
            </DialogContent>
            <DialogActions>
                <Button variant = "contained" color = "inherit" onClick = {closeHandler(null)}>RENUNȚĂ</Button>
                <Button variant = "contained" color = "primary" onClick = {printHandler}>TIPĂREȘTE</Button>
            </DialogActions>
        </Dialog>
    )
}
