import * as localforage from 'localforage'

const chunkSize =  500000;

const chunkify = (data="", chunkSize) => {
    let chunks = []
    for ( let i=0; i<=data.length; i=i+chunkSize) {
        let segment = data.slice(i, i+chunkSize)
        if ( segment.length > 0 ) {
            chunks.push(segment)
        }
        
    }
    return chunks 
}

localforage.config({
    driver      : localforage.INDEXEDDB, 
    name        : 'twixplix',
    version     : 1.0,
    storeName   : 'db', // Should be alphanumeric, with underscores.
    description : 'plix db'
})

export const tweakedLocalStorage = {
    getItem: async key => {
        console.log("GET", key)
        let cacheSize = await localforage.getItem(`${key}-cache-size`)
       
        cacheSize = cacheSize || 0
        console.log("get - cache size: ", cacheSize)
        let chunks = []
        for (let i=0; i<cacheSize; i++) {
            chunks.push(await localforage.getItem(`${key}-fragment-${i+1}`))
        }
        return Promise.all(chunks).then(
            r=> {
                let txtData = r.reduce( (acc,item) => acc+item, "")
                return txtData || null
            },
            err => console.log("data retrival error: ", err)
        )        
    },
    setItem: async (key, value) => {
        if ( value ) {
            console.log("VALUE LENGTH: ", value.length)
            //get all chuncks size 
            let oldCacheSize = await localforage.getItem(`${key}-cache-size`)
            //remove old storage chunks
            let chuncksPromises = []
            for (let i=1; i<oldCacheSize; i++) {
                let itemName = `${key}-fragment-${i+1}`
                chuncksPromises.push( localforage.removeItem(itemName))
            }
            let initialCounterClean = await localforage.removeItem(`${key}-cache-size`)

            let firstStepDone = await Promise.all([initialCounterClean, ...chuncksPromises])
            console.log("first step done!!! removed all items")
            //write new counter+chunks to store
            if ( firstStepDone ) {
                const chunks = chunkify(value, chunkSize) 
                let res = await localforage.setItem(`${key}-cache-size`, chunks.length)
                let newChunksPromises  = chunks.map( (e,i) => {
                    localforage.setItem(`${key}-fragment-${i+1}`, e)
                })
                return Promise.all([res, ...newChunksPromises]).then( 
                    res => {console.log("second step done!!! data imported");return value},
                    err => console.log("some error occured while writing data: ", err)
                )
            }
            
        }

        
    },
    removeItem: async (key) => {
        console.log("REMOVE", key)
        let cacheSize = await localforage.getItem(`${key}-cache-size`) || 1
        let itemsToRemove = [], storageCounter
        for (let i=1; i<cacheSize; i++) {
            let itemName = `${key}-fragment-${i+1}`
            itemsToRemove.push(localforage.removeItem(itemName))
        }
        
        storageCounter = localforage.removeItem(`${key}-cache-size`)

        return Promise.all([storageCounter,...itemsToRemove])
    }

}
    



