import { Grid, Typography } from '@material-ui/core'
import React, { Fragment } from 'react'
const FIELDS = [
    { key: "str", label: "STRADA", xs: 12, sm: 12, align: "left" },
    { key: "nr", label: "NR.", xs: 12, sm: 3 },
    { key: "bl", label: "BLOC", xs: 12, sm: 2 },
    { key: "sc", label: "SCARA", xs: 12, sm: 2 },
    { key: "et", label: "ETAJ", xs: 12, sm: 2 },
    { key: "ap", label: "AP.", xs: 12, sm: 3 },
    { key: "loc", label: "LOCALITATE", xs: 12, sm: 5 },
    { key: "sect", label: "SECTOR", xs: 12, sm: 3 },
    { key: "jud", label: "JUDEȚ", xs: 12, sm: 4 },
    { key: "cp", label: "COD POȘTAL", xs: 12, sm: 3 },
    { key: "tara", label: "ȚARA", xs: 12, sm: 9 },
]
export default function InlineContact(props) {
    const { data } = props || {}
    return (<Fragment>
            {data ? (
                <Grid container justifyContent="center" alignItems="center" spacing={0}>
                    {FIELDS.map((e, i) => (
                        <Grid item key={i}>
                            <Typography variant = "overline" color = "primary">
                            {e.label.toLowerCase()}: &nbsp;
                            </Typography>
                            <Typography variant = "caption" color = "secondary">
                            {data[e.key] ? data[e.key] : "- - - "} &nbsp;&nbsp;
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            ): <Typography variant = "overline">VĂ RUGĂM SĂ ALEGEȚI UN EXPEDITOR DIN AGENDA APLICAȚIEI</Typography>}
        </Fragment>

    )
}
