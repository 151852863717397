import React, {Fragment, useEffect, useState} from 'react'
import { useParams  } from 'react-router'
import { Grid, Card, Container, Typography} from "@material-ui/core"

export default function Error(props) {
   
    const params = useParams()
    const [status, setStatus] = useState()
    console.log( "props:", params)


    useEffect(() => {
        if ( typeof params.code === 'undefined'  ) {
            setStatus({
                code: "404",
                msg: "NOT FOUND",
                desc: "The page you were looking for was not found on our server"
            })
        } else {
            setStatus({code: params.code})
        }
    }, [ params])
    

   
    return (
<Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  justifyContent="center"
  
  style={{ minHeight: '100vh' }}
>
        <Container maxWidth="lg" >
            <Card style = {{padding: "10px", textAlign: "center", background: "rgba(229,234,255,1)"}}>
            {status && (
                <Fragment>
                    <Typography variant = "h1" style={{fontWeight:900}} color = "secondary">{status.code}</Typography>
                    <Typography variant = "h4" color = "primary">{status.msg}</Typography><br/>
                    <Typography variant = "h6" color = "inherit">{status.desc}</Typography>
                </Fragment>
            )}
            </Card>
        </Container>
        </Grid>
    )
}
