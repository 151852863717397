const env = process.env.NODE_ENV

const config = {
    development: {
        APP_NAME: "PLIX",
        NAMESPACE: "cores-dev",
        url: "http://localhost:3000"

    },
    production: {
        APP_NAME: "PLIX",
        NAMESPACE: "cores-prod",
    }
}

export default config[env]