import { Dialog, DialogTitle, DialogContent, Grid, IconButton, makeStyles, Button, DialogActions, Typography } from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { MdClose, MdPrint } from 'react-icons/md'
import styled, { createGlobalStyle } from 'styled-components'

import { Fragment } from 'react'
import AddressLabel from './AddressLabel'

const useStyles = makeStyles(theme => ({
    dialog: { height: "90vh", minWidth: "90vw" }

}))



const Styles = styled.div`
    @media print {
        html,
        body {
          height: initial !important;
          overflow: initial !important;
          -webkit-print-color-adjust: exact;
          -webkit-filter:opacity(1);
        }
        @page {
            size:  a4;
            margin: 0mm;
        }
        
        .square-container-having-the-page-width{
            visibility: visible;
            position: relative; top:0;left:0;
            width: 210mm; height: 210mm;
            display:flex;align-items: center;justify-content: flex-end;
            transform: rotate(270deg) translate(0, 0);
            transform-origin: center;
        }

        .envelope {
           
        }
    }


    @media screen {
        .container {
            border: 2px dashed red;
            height:100%;width:100%;
            margin: 10px;
        }
        .envelope {
            // background: white;
            // width: 220mm;
            // height: 110mm;
            // display: block;
            margin: 0 auto;
            margin-bottom: 0cm;
            box-shadow: 0 0 0.2cm rgba(0,0,0,0.5);
        }


    };


  
.bkgTxt {
    position:absolute; 
    z-index:1;
    margin-top:10mm;
    margin-left:0mm;
    font-size:5em;
    font-weight:800;
    color:light-grey;
    opacity:0.2;
    line-height:3;
    transform: rotate(-20deg);
   
} 
`




const Item = props => (
    <Typography variant="caption">{props.title ? props.title + ": " : ""} <strong>{props.text || "- - - - - "}</strong></Typography>
)

export default function PlicTpl({ exp, dest, tpl }) {
    const classes = useStyles()

    console.log("plictpldata:", exp, dest, tpl)
    return (
        <Fragment>
            {exp && dest && (
                <Fragment >
                    <Styles>
                        <div className="square-container-having-the-page-width" >

                            <div className="envelope" style = {tpl && tpl.value && {height: tpl.value.height+"mm", width: tpl.value.width+"mm"}}>
                            
                            {tpl && tpl.value && tpl.value.header && tpl.value.header.visible && (
                                <div style = {{...tpl.value.header,width: "100%", height: "auto"} }dangerouslySetInnerHTML={{__html:  tpl.value.header.content}}></div>
                            )}

                               {console.log("tttppplll:", tpl&&tpl.value)}
                            <div style = {tpl && tpl.value && tpl.value.exp}>
                                <strong>EXPEDITOR</strong><br/><br/>
                                <AddressLabel {...exp} />
                            </div>
                            <div style = {tpl && tpl.value && tpl.value.dest}>
                                <strong>DESTINATAR</strong><br/><br/>
                                <AddressLabel {...dest} />
                            </div>
                            </div>
                        </div>

                    </Styles>
                    
                </Fragment>
            )}

        </Fragment>

    )
}