import React, { useState, useEffect, Fragment } from 'react'
import Container from '../../components/Container'
import Layout from '../../components/Layout'

import { Button, Grid, makeStyles, Toolbar, Typography, useTheme,  } from '@material-ui/core'
import { Switch, Route, Link, useHistory, useParams } from 'react-router' 
import SetPlic from './SetPlic'
import DynamicIcon from '../../components/DynamicIcon'
import Backup from './Backup'
import Restore from './Restore'
import Reset from './Reset'


const useStyles = makeStyles(theme => ({
    toolbar: {
        background: theme.palette.grey[100], margin: 0
    }
}))



export default function Setari(props) {

    const classes = useStyles()
    const history = useHistory()
    const theme = useTheme()
    const MENU_ITEMS = [
        {
            label: "PLICURI",
            component: "FaEnvelope",
            color: {background: theme.palette.primary.dark, color: theme.palette.primary.contrastText},
            link: "/setari/plic/"
        },
        {
            label: "BACKUP",
            component: "FaSave",
            color: {background: theme.palette.info.dark, color: theme.palette.info.contrastText},
            link: "/setari/backup"
        },
        {
            label: "RESETARE",
            component: "FaTimesCircle",
            color: {background: theme.palette.secondary.dark, color: theme.palette.secondary.contrastText},
            link: "/setari/reset"
        },
        {
            label: "RESTAURARE",
            component: "FaRecycle",
            color: {background: theme.palette.success.dark, color: theme.palette.secondary.contrastText},
            link: "/setari/restore"
        }
    ]

    return (
        
        <Layout title = "SETĂRI APLICAȚIE">
          
            <Container title = " CONFIGURĂRI">
               <Toolbar variant = "dense" className = {classes.toolbar}>
                    <Grid container spacing={1}>
                    {MENU_ITEMS.map( (e,i) => (
                        <Fragment key = {i}>
                           <Grid item xs = {12} sm ={3}>
                        <Button variant = "contained" size = "small" style = {e.color} fullWidth
                            onClick = {()=> history.push(`${e.link}`)}
                        >
                         
                           <DynamicIcon name = {e.component} size="1.2rem" color = "inherit"/>
                            &nbsp;&nbsp;
                               <Typography variant = "body1">
                            {e.label}
                            </Typography>
                        </Button>
                        
                        </Grid>
                        </Fragment>
                    ))}
                    </Grid>
               </Toolbar>
                <Route path = "/setari/plic" component={SetPlic} />
                <Route path = "/setari/reset" component={Reset} />
                <Route path = "/setari/backup" component={Backup} />
                {/* <Route path = "/setari/restore" exact component={()=><Restore/>}/> */}
                <Route path = "/setari/restore/:demo?" component={()=><Restore/>} />
                
                
            </Container>
        </Layout>
    )
}
