import React from "react"
import { makeStyles, Card, Grid, Typography, Toolbar, useTheme, useMediaQuery, CardActions } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    card: { margin: theme.spacing(0.5),padding: 0, height: '100%', border: `1px solid ${theme.palette.grey[400]}`, boxShadow: '1px 1px 2px lightgrey',
    backgroundColor: "rgba(0,0,0,0)" },
    cardToolbar: { 
        // background: '#dde2ff', color: theme.palette.primary.dark, 
        background: theme.palette.grey[300], color: theme.palette.warning.contrastText,
        margin: 0, minHeight: theme.spacing(4),
        marginRight: 0, paddingRight: 0, paddingLeft: theme.spacing(1)
        
    },
    cardContent: { padding: theme.spacing(1),  overflow: "auto", backgroundColor: "rgba(0,0,0,0)", background: "none", }
}))


const RichCard = props => {
    const width = useMediaQuery(useTheme().breakpoints.up('sm'))
    const classes = useStyles()
    return (
        <Card className={classes.card} style = {props.style}>
            {Boolean(props.title) && (
                <Toolbar variant="dense" className={classes.cardToolbar}>
                    <Grid container justifyContent = "space-between" alignItems = "center" >
                    <Typography variant="caption" style = {props.titleStyle}><strong>{props.title}</strong></Typography>
                        <div align = "right">{props.action  && <div align = "right">{props.action}&nbsp;&nbsp;</div>}</div>
                    </Grid>
                </Toolbar>
            )}
            <div className={classes.cardContent} style = {props.contentStyle||null}>
                {props.children}
            </div>
            <CardActions><div style = {{width: "100%"}}>{props.footer}</div></CardActions>
        </Card>
    )
}

export default RichCard