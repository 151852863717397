import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Grid, useTheme, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import FunkyInput from '../../../components/FunkyInput'
import { UUID } from '../../../lib/uuid'
import iso from './iso'

export default function NewPlicModal({open, onClose}) {

    const [item, setItem] = useState()
    const theme = useTheme()
    const [defaultFormat, setDefaultFormat] = useState({})

    useEffect(() => {
        setItem({
            group: "plic",
            name: "PLIC NOU",
            label: "PLIC",
            desc: "descriere plic nou",
            key: "style",
            value: {
                width: 220,
                height: 110,
                exp: {
                    position: "relative",
                    boxShadow: "2px 2px 10px grey", 
                    background: theme.palette.grey[400], 
                    marginTop: "5mm",
                    marginLeft: "5mm",
                    height: "40mm",
                    width: "70mm",
                    background: "floralwhite",
                    fontSize: "14px",
                    borderRadius: "5mm",
                    textAlign: "left",
                    padding: "3mm", 
                    opacity:1,
                    cursor: "move"
                   
                }, 
                dest: {
                    position: "relative",
                    boxShadow: "2px 2px 10px grey", 
                    background: theme.palette.grey[400], 
                    marginTop: "-15mm",
                    marginLeft: "110mm",
                    height: "40mm",
                    width: "70mm",
                    background: "lightblue",
                    fontSize: "14px",
                    borderRadius: "5mm",
                    textAlign: "left",
                    padding: "3mm", 
                    opacity:1,
                    cursor: "move"
                   
                }, 
                header: {
                    position: "relative",
                    visible: true,
                    opacity: 0.5,
                    background: "white", borderBottom: "1px dashed grey",
                    content: "<h1>ANTETUL COMPANIEI SAU A INSTITUTIEI TALE</h1>",
                    width: "100%",
                    height: "auto",
                    marginTop: 0,
                    marginLeft: 0,
                }
            },
            default: true

        })
        setDefaultFormat({})
    }, [open, onClose])

    const closeHandler = el => () => onClose(el || false)

    const inputHandler = name => ev => setItem({
        ...item, 
        [name]: ev.target.value
    })

    const valueHandler = name => ev => setItem({
        ...item,
        value:{
            ...item.value,
            [name]: ev.target.value
        }
    })

    const applyFormat = () => {
        let ni = {...item}
        setItem({
            ...ni,
            name: defaultFormat.name,
            label: defaultFormat.label,
            desc: defaultFormat.desc,
            value: {
                ...ni.value,
                ...defaultFormat.value
            }
        })
    }

    return (
        <Dialog open = {Boolean(open)} onClose = {closeHandler()}>
            <DialogTitle>ADĂUGARE FORMAT NOU DE PLIC</DialogTitle>
            <DialogContent>
                {item && (
                    <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
                        <Grid item xs = {12} sm = {6}>
                            <FunkyInput value = {item.name} label = "TITLU" onChange = {inputHandler('name')}/>
                        </Grid>
                        <Grid item xs = {12} sm = {12}>
                            <FunkyInput value = {item.desc} label = "DESCRIERE"  onChange = {inputHandler('desc')}/>
                        </Grid>
                        <Grid item xs = {12} sm = {3}>
                            <FunkyInput 
                                type="number" step = {1} value = {item.value.width} label = "LĂȚIME"
                                onChange = {valueHandler('width')}
                            />
                        </Grid>
                        <Grid item xs = {12} sm = {3}>
                            <FunkyInput 
                                type="number" step = {1} value = {item.value.height} label = "ÎNĂLȚIME"
                                onChange = {valueHandler('height')}
                            />
                        </Grid>
                        <Grid item xs ={12}>
                            <Typography variant="caption"> Preluare proprietățile format ISO </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <FunkyInput value = {defaultFormat} select onChange = {ev=>setDefaultFormat(ev.target.value)}>
                                {[...iso].map( (e,i) => (
                                    <option value = {e} key={i}>{e.name} ({e.value.width}mm x {e.value.height}mm)</option>
                                ))}
                            </FunkyInput>
                        </Grid>
                        <Grid item xs={2}>
                            <Button size="small" variant="contained" color ="secondary" onClick={applyFormat}>
                                APLICĂ
                            </Button>
                        </Grid>
                    </Grid>

                )}
            </DialogContent>
            <DialogActions>
                <Button variant = "contained" color = "secondary" onClick = {closeHandler()}>
                    RENUNȚĂ
                </Button>
                <Button variant = "contained" color = "primary" onClick = {closeHandler(item)}>
                    CONFIRMĂ
                </Button>
            </DialogActions>
        </Dialog>
    )
}
