import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export default function BorderouTpl(props) {


    let { exp, dest } = props || {}


    return (
        <Styles>
            <div className={'container'}>
                <Grid container justifyContent="center" alignItems="flex-end">
                    <Grid item xs={12}>
                        {props.page && <div>Pagina {props.page}</div>}
                    </Grid>
                    <Grid item xs={12}>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <td style={{ paddingleft: '20px' }} colSpan="2">
                                        <p>Beneficiar <br /><strong>{exp[0].name}</strong></p>
                                        <p>Adresa:<br /> str. <strong>{exp[0].str}</strong>, nr. <strong>{exp[0].nr}</strong>, bl.
                                            <strong>{exp[0].bl}</strong>, sc. <strong>{exp[0].sc}</strong>, et. <strong>{exp[0].et}</strong>,
                                            ap. <strong>{exp[0].ap}</strong>,<br /> loc. <strong>{exp[0].loc}</strong>, com. <strong>{exp[0].com}</strong>,
                                            jud. <strong>{exp[0].jud}</strong>, cod poștal <strong>{exp[0].cp}</strong>,</p>
                                    </td>
                                    <td colSpan="4"><strong> BORDEROU<br />Pentru trimiteri de recomandate prezentate &icirc;n
                                        serie</strong></td>
                                    <td colSpan="5">Borderou nr .....................<br /> de trimiteri recomandate prezentate &icirc;n
                                        serie la oficiul poştal .................<br />&icirc;n ziua .............. luna .............. anul
                                        ........................</td>
                                </tr>
                                <tr>
                                    <td rowSpan="2">
                                        <p>Nr.crt.</p>
                                    </td>
                                    <td rowSpan="2">
                                        <p>Denumirea sau numele complet al destinatarului</p>
                                    </td>
                                    <td rowSpan="2">Destinatia&nbsp;</td>
                                    <td rowSpan="2">
                                        <p>Servicii suplimen- tare</p>
                                        &nbsp;
                                    </td>
                                    <td rowSpan="2">Greutate&nbsp;</td>
                                    <td colSpan="2">
                                        <p>Tarife<br />(scutit de TVA fara drept de deducere)</p>
                                    </td>
                                    <td colSpan="2">Tarife<br />(cu TVA inclus)</td>
                                    <td rowSpan="2">Nr.prezentare&nbsp;</td>
                                    <td rowSpan="2">Obs.&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>lei</td>
                                    <td>B</td>
                                    <td>lei</td>
                                    <td>B</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '5%' }}><small>1</small></td>
                                    <td style={{ width: '30%' }}><small>2</small></td>
                                    <td style={{ width: '15%' }}><small>3</small></td>
                                    <td style={{ width: '5%' }}><small>4</small></td>
                                    <td style={{ width: '5%' }}><small>5</small></td>
                                    <td style={{ width: '5%' }}><small>6</small></td>
                                    <td style={{ width: '5%' }}>&nbsp;</td>
                                    <td style={{ width: '5%' }}><small>7</small></td>
                                    <td style={{ width: '5%' }}>&nbsp;</td>
                                    <td style={{ width: '15%' }}><small>8</small></td>
                                    <td style={{ width: '15%' }}><small>9</small></td>
                                </tr>
                            </thead>
                            <tbody>
                                {dest.map((e, i) => (
                                    <tr key={'tableitem-' + i}>
                                        {/* <td className="td-center">{index === 0 ? i+1 : index+''+(i+1) }</td> */}
                                        <td className="td-center">{i + 1}</td>
                                        <td className="text-left">{e.name}</td>
                                        <td className="text-left">{e.loc}, JUD. {e.jud} </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>))
                                }
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item xs = {12}>
                    <Grid container direction="row" justifyContent="space-around" alignItems="center">
                    <div style={{ textAlign: 'center' }}>
                        <br /><br />
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ display: 'inline-block', height: '40px', width: '40px', border: '.07em dashed black', borderRadius: '40px', textAlign: 'center' }} />
                        </div>
                        Ștampila beneficiarului,

                        <div style={{ textAlign: 'center' }}>
                            <div style={{ display: 'inline-block', height: '40px', width: '40px', border: '.07em dashed black', borderRadius: '40px', textAlign: 'center' }} />
                        </div>
                        Ștampila cu data prezentării,

                    </div>

                    <div>
                        <br />
                        Eliberat chitanţa de la nr................ la nr................ <br />
                        (primul şi ultimul număr de prezentare)<br /><br />

                        Salariat poştal <br />
                        (numele şi prenumele, semnătura)

                    </div>

                </Grid>
                    </Grid>


              
            </Grid>
                </div>
       

        </Styles >
    )
}


const Styles = styled.div` 
table {
    border-collapse: collapse;
    font-size:1em;

}

table, td, th {
    border: 0.1em solid black;
    text-align:center;

   
}
table td { padding: 3px;}
.xsCell  {width:50px;}
.smCell  {width:100px;}
.mdCell {width:250px;}
.lgCell {min-width:200px;}
.td-center {text-align:center;}
.th-text { font-weight: 600;}
.text-left { text-align: left; }
}

@media print {
    table { page-break-inside:auto; zoom: 1 }
    tr    { page-break-inside:avoid; page-break-after:auto }
}

@media screen {
    .container { width: 100%;}
}
`
