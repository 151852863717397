import { Grid, Typography, useTheme } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { Fragment, useState } from 'react'
import FunkyInput from '../../../components/FunkyInput'
import { JSONEditor } from "react-json-editor-viewer";

const OPT = [
    { key: "name", label: "DENUMIRE", xs: 12, sm: 12 },
    { key: "desc", label: "DESCRIERE", xs: 12, sm: 12 },
    { parent: "value", key: "width", label: "LATIME (mm)", xs: 12, sm: 6 },
    { parent: "value", key: "height", label: "ÎNĂLȚIME (mm)", xs: 12, sm: 6 },
]



export default function Options({ data, onChange , onTabChange, tab}) {

    const inputHandler = name => ev => onChange({...data, [name]: ev.target.value })
    const valHandler = name => ev => onChange({ ...data, value: { ...data.value, [name]: ev.target.value } })
    const theme = useTheme()
    const jsonHandler = type => (key, val, orig, modif) => {

        let nd = { ...data }
        nd.value = { ...nd.value, [type]: modif }
        console.log(nd)
        onChange(nd)
    }


    return (
        <Fragment>
            <Grid container spacing={1} style={{ padding: "5px" }}>

                {data
                    && OPT.map((e, i) => (
                        <Grid item xs={e.xs || 12} sm={e.sm || 12} key={i}>
                            {!e.parent
                                ? <FunkyInput value={data[e.key]} label={e.label} onChange = {inputHandler(e.key)} />
                                : <FunkyInput value={data['value'][e.key]} label={e.label} type="number" step="1" onChange={valHandler(e.key)} />
                            }


                        </Grid>
                    ))
                }
                <div align="left" style={{ paddingLeft: theme.spacing(1) }}>
                    <Typography variant="caption" component="a" onClick={() => onTabChange("header")}
                        style={tab === "header" ? { fontWeight: 600 } : null}
                        color={tab === "header" ? "primary" : "inherit"}
                    >
                        ANTET
                    </Typography> &nbsp;&nbsp;
                    <Typography variant="caption" component="a" onClick={() => onTabChange("exp")}
                        style={tab === "exp" ? { fontWeight: 600 } : null}
                        color={tab === "exp" ? "primary" : "inherit"}>
                        EXPEDITOR
                    </Typography> &nbsp;&nbsp;
                    <Typography variant="caption" component="a" onClick={() => onTabChange("dest")}
                        style={tab === "dest" ? { fontWeight: 600 } : null}
                        color={tab === "dest" ? "primary" : "inherit"}>
                        DESTINATAR
                    </Typography>
                    <br />
                </div>
                <Grid container style={{ border: "1px solid grey", height: "35vh", overflow: "scroll" , paddingTop: 10}}>
                    {data && data.value && (
                        <Fragment>


                            {tab === "header" && (
                                <Fragment>
                                    <JSONEditor data={data.value.header} onChange={jsonHandler('header')} collapsible />
                                </Fragment>
                            )}
                            {tab === "exp" && (
                                <Fragment>
                                    <JSONEditor data={data.value.exp} onChange={jsonHandler('exp')} collapsible />
                                </Fragment>
                            )}
                            {tab === "dest" && (
                                <Fragment>
                                    <JSONEditor data={data.value.dest} onChange={jsonHandler('dest')} collapsible />
                                </Fragment>
                            )}


                        </Fragment>
                    )}
                </Grid>


            </Grid>
        </Fragment>
    )
}
