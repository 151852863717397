import {Toolbar, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, makeStyles } from '@material-ui/core'
import React, { useEffect, useState, useRef } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import PlicTpl from '../PrintTemplates/PlicTpl'
import {useReactToPrint} from 'react-to-print'
import styled from 'styled-components'
import { Fragment } from 'react'


const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minWidth: "95vw", minHeight: "95vh"
    },
    toolbar: { background: theme.palette.grey[200], display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "10px" }
}))

export default function BatchPlicuri(props) {
    const printRef = useRef()
    const classes = useStyles()
    const {data} = props || {}
    const [tpl, setTpl] =  useState({})

    useEffect( () => {
        if (data && data.settings) {
            let defaultTpl = data.settings.filter( el => !!el.default)
            defaultTpl = defaultTpl.length > 0 ? defaultTpl[0] : {}
            setTpl(defaultTpl)
        }
    }, [])
    const closeHandler = ()=>props.onClose(null)

    const switchTplHandler = el => () => {
        setTpl(el)
    }

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });
     
    return (
        <Dialog open = {Boolean(data)} onClose= {closeHandler} classes = {{paper: classes.dialogPaper}}>
            <DialogTitle>
                <Grid container justifyContent = "space-between" alignItems = "center">
                TIPĂRIRE PLICURI
                <IconButton size = "small" onClick = {closeHandler}>
                    <FaTimesCircle color = "lightgrey" size = "1.4rem"/>
                </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>

                <Toolbar className = {classes.toolbar} variant="dense">
                    {data && data.settings && data.settings.map( (e,i) => (
                        <Button key={i} 
                            onClick = {switchTplHandler(e)} 
                            color="primary"
                            variant = {e === tpl ? "contained": "outlined"}>
                                {e.name}
                        </Button>
                    ))}
                </Toolbar>  
                <div style = {{height: "70vh", overflow: "auto"}}>
                <div  ref = {printRef} >
                    <Styles>
                        {data && data.dest && data.dest.map( (e,i) => (
                            <Fragment key = {i}>
                            <PlicTpl exp = {data.exp[0]} dest = {e} tpl = {tpl} />
                            <div className = "page-break" />
                            </Fragment>
                        ))}
     
                    </Styles>
                </div>              
                </div>
                
            </DialogContent>
            <DialogActions>
                <Button variant = "contained" color = "inherit" onClick = {closeHandler}>RENUNȚĂ</Button>
                <Button variant = "contained" color = "primary" onClick = {printHandler}>TIPĂREȘTE</Button>
            </DialogActions>
        </Dialog>
    )
}


const Styles = styled.div`{
    @media print {
        .page-break {
            margin-top: 0;
            height: 0;
            background: grey;
            display: block;
            page-break-after: always;
        }
    }
    @media screen {
        .page-break {
            margin-top:0;height: 1px;
            padding: 5px;

        }
    }
}`