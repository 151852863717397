import { Button, Card, Checkbox, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Tooltip, useTheme } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import DynamicIcon from '../../../components/DynamicIcon'
import RichCard from '../../../components/RichCard'
import { useQuery, useMutation } from "@apollo/client"
import { QUERY_ALL_DATA, SETTING, SETTING_MUTATION, SETTING_RM_MUTATION } from '../../../apollo/resolvers'
import { FaCheck, FaPlusCircle, FaTimesCircle } from 'react-icons/fa'
import NewPlicModal from './NewPlicModal'
import Options from './Options'
import Preview from './Preview'
import Notification from '../../../components/Notification'

const useStyles = makeStyles(theme => ({
    listItem: {
        border: `1px dotted ${theme.palette.grey[400]}`, paddingTop: 0, paddingBottom: 0
    }
}))

export default function SetPlic(props) {
    const classes = useStyles()
    const theme = useTheme()
    const [items, setItems] = useState()
    const [curIndex, setCurIndex] = useState(0)
    const [curItem, setCurItem] = useState()
    const [newPlicModal, setNewPlicModal] = useState(false)
    const [notify, setNotify] = useState()
    const { data, loading, error, refetch } = useQuery(SETTING)
    const [mutate, dispatchMutate] = useMutation(SETTING_MUTATION)
    const [rm] = useMutation(SETTING_RM_MUTATION)
    const [tab, setTab] = useState('header')
    const getAll = useQuery(QUERY_ALL_DATA)

    useEffect(() => {
        if (data) setItems(data.Setting)
    }, [data])

    const newPlicHandler = () => setNewPlicModal(true)
    const closeNewPlicHandler = ev => {
        if (ev) {
            mutate({ variables: { data: ev } }).then(res => {
                console.log("Res:", res)
                setNewPlicModal(false)
                if (items) {
                    setCurIndex( items.length)
                    setCurItem(items[items.length])
                }

            })
        }
        setNewPlicModal(ev)
    }

    const rmPlicHandler = id => () => {
        console.log("id: ", id)
        rm({ variables: { id: id } }).then(() => refetch())
    }

    const setCurrentItemHandler = (item,index) => () => {
        setCurIndex(index)
        setCurItem(item)
    }

    const changeOptionsHandler = ev => {
        let nd = [...items]
        nd[curIndex] = ev
        setCurItem(ev)
        setItems(nd)
    }

    const submitHandler = () => {
        console.log("curItem:", curItem)
        mutate({variables: {data:curItem}}).then( 
            r => {
                setNotify(true)
                refetch()
                getAll.refetch()
            },
            err => console.log("mutation error:", err)
        )
    }

    const toggleDefaultHandler = ev => { 
        let ni = {...curItem}
        ni.default = ev.target.checked
        setCurItem(ni)
       
    }

    return (
        <RichCard 
            title="CONFIGURARE PLICURI" 
            contentStyle={{ height: "64vh", overflow: "hidden" }} 
            style={{ height: "70vh", overflow: "hidden" }}
            action = {<IconButton onClick = {submitHandler}><FaCheck color = "red"/></IconButton>}
        >
            <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                <Grid item sm={3} lg={2} xs = {12}>
                    <Button fullWidth variant="contained" size="small" color="secondary" onClick={newPlicHandler}>
                        <FaPlusCircle size="1.2rem" /> &nbsp;&nbsp;&nbsp;ADAUGĂ PLIC
                    </Button>
                    <List component="nav">
                        {items && items.map((e, i) => (
                            <ListItem button 
                                className={classes.listItem} key = {i} 
                                style ={ {background: i === curIndex ? "lightgrey": "white"}}
                                onClick = {setCurrentItemHandler(e,i)}>
                                <ListItemText
                                    primary={e.name}
                                    secondary={<small>{e.desc}</small>}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton color="secondary" onClick={rmPlicHandler(e.id)}>
                                        <FaTimesCircle />
                                    </IconButton>
                                </ListItemSecondaryAction>


                            </ListItem>
                        ))}

                    </List>
                </Grid>
                <Grid item sm={9} lg={10} xs = {12}>
                    
                        {curItem  && (
                            <RichCard 
                                title = {items[curIndex] && items[curIndex].name}
                                action = {
                                    <Tooltip title="model implicit">
                                        <input 
                                            type="checkbox" size="small" 
                                            value={curItem.default||false} 
                                            checked={curItem.default}
                                            onChange = {toggleDefaultHandler} 
                                        />
                                    </Tooltip>
                                }
                            >

                            <Grid container alignItems="flex-start">
                                <Grid item sm = {4} xs = {12}>
                                    <Options data = {curItem} 
                                        onChange = {changeOptionsHandler}  
                                        onTabChange = {ev => setTab(ev)}
                                        tab = {tab}
                                    />
                                </Grid>
                                <Grid item sm = {8} xs = {12}>
                                    <Preview 
                                        data = {curItem} 
                                        onChange = {ev => setCurItem(ev)}
                                        onTabChange = {ev => setTab(ev)}
                                    />
                                </Grid>
                            </Grid>
                            </RichCard>
                        )}
                    
                </Grid>
            </Grid>
            <NewPlicModal open={newPlicModal} onClose={closeNewPlicHandler} />
            <Notification show = {notify} onClose = {()=>setNotify()}/>
        </RichCard>
    )
}
