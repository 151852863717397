import gql from "graphql-tag";

export const schema = gql`

    type Contact { id:String name:String str:String nr:String bl:String sc:String et:String ap:String
                    jud:String sect:String loc:String com:String cp:String tara:String  }

    type Destinatar {id:String}
    type Expeditor {id:String}

    type Box { background:String borderRadius:String boxShadow:string height:String marginLeft:String marginTop:String
    opacity:Float padding:String position:String textAlign:String width:String}

    type Header { visibile:Boolean, background:String, borderBottom:String content:String opacity:Float position:String width:String}

    type Setting { id:String group:String label:String desc:String key:String value:String, default: false }

    input ImportInput { Contact:[Contact] Destinatar: [Destinatar] Expeditor:[Expeditor] Settings:[Setting] }
    input ResetInput { Contact:Boolean Destinatar:Boolean Expeditor:Boolean Setting:Boolean }

`