import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@material-ui/core'
import React, {useState, useEffect, Fragment} from 'react'
import { FaTimesCircle } from 'react-icons/fa'

export default function ARmodal(props) {

    const [item, setItem ] = useState()

    useEffect(() => {
        setItem(props.data)
    }, [props])

    const closeHandler = item => () => {
        if ( !item ) { props.onClose(null)
        } else {
            props.onClose(item)
        }
    }
    return (
        <Dialog open = {Boolean(item)} onClose = {closeHandler(null)}>
            <DialogTitle >
                <Grid container justifyContent="space-between" alignItems="center">
                    TIPĂRIRE CONFIRMARE PRIMIRE (A.R.)
                    <Grid item align="right">
                        <IconButton onClick = {closeHandler(null)}><FaTimesCircle color="lightgrey" size = "1em"/></IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                    {item &&( 
                        <Fragment>
                            {JSON.stringify(item, null, 4)}
                        </Fragment>
                    )}
            </DialogContent>
            <DialogActions>
                <Button color="secondary" variant="contained" size="small" onClick={closeHandler(null)}>
                    RENUNȚĂ
                </Button>
            </DialogActions>
        </Dialog>
    )
}
