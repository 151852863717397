import { Alert } from '@material-ui/lab'
import React, { Fragment } from 'react'

export default function Error(props) {
    const {title, children, type = "info"} = props || {}
    return (
        <Fragment>
            {props && (
                <Alert title = {title} type={type}>
                    {children}
                </Alert>
            )}
        </Fragment>
     
    )
}
