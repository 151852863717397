import { Grid } from '@material-ui/core'
import React, { Fragment } from 'react'

const FIELDS = [
    { key: "name", label: " ", size: 12 },
    { key: "str", label: "Str.", size: "auto" },
    { key: "nr", label: "Nr.", size: 3 },
    { key: "", label: "", size: 12 },
    { key: "bl", label: "Bl.", size: "auto" },
    { key: "sc", label: "Sc.", size: "auto"  },
    { key: "et", label: "Et.", size: "auto" },
    { key: "ap", label: "Ap.", size: "auto" },
    { key: "", label: "", size: 2 },
    { key: "cp", label: "Cod poștal", size: "auto" },
    { key: "loc", label: "Localitatea", size: "auto" },
    { key: "jud", label: "Județul", size: "auto" },
    { key: "tara", label: "Țara", size: "auto" },
]
export default function AddressLabel(props) {
    const { exp, dest, tpl } = props
    let data = props.data || {}
    const Text = props => (
        <Fragment>
            {(props && props.children)
                ? <strong>{props.children}</strong>
                : "-"
            }
        </Fragment>
    )
    return (
        <Grid container justifyContent="flex-start">
            {props && FIELDS.map((e, i) => (
                <Grid key={i} item xs={e.size}>
                    {e.key && (
                        <Fragment>
                            {e.label} {' '}
                            <Text>{props[e.key]}</Text> &nbsp;&nbsp;
                        </Fragment>
                    )}

                </Grid>
            ))}
        </Grid>
    )
}
