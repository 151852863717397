import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, makeStyles } from '@material-ui/core'
import React, { useRef } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import styled from 'styled-components'
import { useReactToPrint } from 'react-to-print'
import pcp from '../../assets/pcp.jpg'

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minWidth: "95vw", minHeight: "95vh", overflow: "hidden"
    }
}))

export default function BatchConfirmari(props) {
    const classes = useStyles()
    const { data } = props || {}
    const { exp, dest } = data || {}
    const printRef = useRef()

    const printHandler = useReactToPrint({
        content: () => printRef.current,
    });

    const closeHandler = () => props.onClose(null)


    return (
        <Dialog open={Boolean(data)} onClose={closeHandler} classes={{ paper: classes.dialogPaper }}>
            <DialogTitle>
                <Grid container justifyContent="space-between" alignItems="center">
                    TIPĂRIRE CONFIRMĂRI ÎN SERIE
                    <IconButton size="small" onClick={closeHandler}>
                        <FaTimesCircle color="lightgrey" size="1.4rem" />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <div ref={printRef}>
                    <Styles>
                        {dest && dest.map((e, i) => (
                            <div className="potrait" key={i}>
                                <div className="container">
                                    {/* destinarar */}
                                    <strong style={{ margin: '205px 0 0 120px', position: 'absolute' }}>{e.name}</strong>
                                    <strong style={{ margin: '245px 0 0 120px', position: 'absolute' }}>{e.str}</strong>
                                    <strong style={{ margin: '245px 0 0 450px', position: 'absolute' }}>{e.nr}</strong>
                                    <strong style={{ margin: '275px 0 0 95px', position: 'absolute' }}>{e.bl ? e.bl : '---'}</strong>
                                    <strong style={{ margin: '275px 0 0 165px', position: 'absolute' }}>{e.et ? e.et : '---'}</strong>
                                    <strong style={{ margin: '275px 0 0 235px', position: 'absolute' }}>{e.sc ? e.sc : '---'}</strong>
                                    <strong style={{ margin: '275px 0 0 300px', position: 'absolute' }}>{e.ap ? e.ap : '---'}</strong>
                                    <strong style={{ margin: '275px 0 0 410px', position: 'absolute' }}>{e.cp ? e.cp : '---'}</strong>
                                    <strong style={{ margin: '305px 0 0 105px', position: 'absolute' }}>{e.loc ? e.loc : '---'}</strong>
                                    <strong style={{ margin: '305px 0 0 255px', position: 'absolute' }}>{e.com ? e.com : '---'}</strong>
                                    <strong style={{ margin: '305px 0 0 395px', position: 'absolute' }}>{e.jud ? e.jud : '---'}</strong>

                                    {/* expeditor */}
                                    {/* <pre>{JSON.stringify(exp,null,4)}</pre> */}
                                    <strong style={{ margin: '200px 0 0 550px', position: 'absolute' }}>
                                        {exp[0].name}
                                    </strong>
                                    <strong style={{ margin: '245px 0 0 560px', position: 'absolute' }}>
                                        {exp[0].str ? exp[0].str : '---'}
                                    </strong>
                                    <strong style={{ margin: '245px 0 0 915px', position: 'absolute' }}>
                                        {exp[0].nr ? exp[0].nr : '---'}
                                    </strong>
                                    <strong style={{ margin: '275px 0 0 550px', position: 'absolute' }}>
                                        {exp[0].bl ? exp[0].bl : '---'}
                                    </strong>
                                    <strong style={{ margin: '275px 0 0 620px', position: 'absolute' }}>
                                        {exp[0].et ? exp[0].et : '---'}
                                    </strong>
                                    <strong style={{ margin: '275px 0 0 685px', position: 'absolute' }}>
                                        {exp[0].sc ? exp[0].sc : '---'}
                                    </strong>
                                    <strong style={{ margin: '275px 0 0 750px', position: 'absolute' }}>
                                        {exp[0].ap ? exp[0].ap : '---'}
                                    </strong>
                                    <strong style={{ margin: '275px 0 0 860px', position: 'absolute' }}>
                                        {exp[0].cp ? exp[0].cp : '---'}
                                    </strong>
                                    <strong style={{ margin: '300px 0 0 560px', position: 'absolute' }}>
                                        {exp[0].loc ? exp[0].loc : '---'}
                                    </strong>
                                    <strong style={{ margin: '300px 0 0 700px', position: 'absolute' }}>
                                        {exp[0].com ? exp[0].com : '---'}

                                    </strong>
                                    <strong style={{ margin: '300px 0 0 870px', position: 'absolute' }}>
                                        {exp[0].jud ? exp[0].jud : '---'}
                                    </strong>
                                </div>
                                {i % 2 == 1 && <div className="page-break" />}
                            </div>
                        ))}
                    </Styles>
                </div>

            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={closeHandler}>RENUNȚĂ</Button>
                <Button variant="contained" color="primary" onClick={printHandler}>TIPĂREȘTE</Button>
            </DialogActions>

        </Dialog>
    )
}


const Styles = styled.div`
    @media all {
        .container {
            position: relative;
            border:1px dashed grey;
            background: url(${pcp});        
            background-size: 1000px auto;
            background-repeat: no-repeat;
            background-position: center;
            width:1000px; height: 550px;
            background-color: '#ffffff';
        }
    }
    @media print {
        html,
        body {
            height: initial !important;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
            -webkit-filter:opacity(1);
        }
        @page {
            size:  a4!important;
            margin: 5mm!important;
        }

        .portrait {
            position: relative; top: 0; left: 0;
            width: 210mm; height: 290mm;
        }
       .page-break {
            margin - top: 1;
            height: 1px;
            background: grey;
            display: block;
            page-break-after: always;
        }
    }`