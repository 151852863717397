import Container from '../../components/Container'
import React, {useState, useEffect} from 'react'
import Layout from '../../components/Layout'
import Logo from '../../assets/plic.svg'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import markdown from './help.md'

console.log("help!!! ", markdown)



export default function Info() {

    const [content, setContent] = useState()
    useEffect( () => {
        fetch(markdown).then((response) => response.text()).then((text) => setContent(text) )
    },[])

    return (
        <Layout>
            <Container title = "DESPRE APLICAȚIE" >
                <img src = {Logo} style = {{opacity: 0.1,  position: "fixed", left: "20%", right: "0%", width: "500px", height: "auto"}}/>
                <div style = {{padding: "3%"}}>
                <ReactMarkdown children = {content} remarkPlugins={[gfm]} >
                </ReactMarkdown>
                </div>
            </Container>
        </Layout>
    )
}
