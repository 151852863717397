import { makeStyles, Tooltip } from '@material-ui/core'
import React from 'react'



export default function ToolTip(props) {
    const useStyles = makeStyles(theme =>({
        customTooltip: {
            // I used the rgba color for the standard "secondary" color
            backgroundColor: 'white', color: theme.palette.primary.dark, padding: theme.spacing(1),
            textAlign: "center", border: "1px solid lightgrey",
            boxShadow: "1px 1px 3px grey",
            ...props.style
        },
        customArrow: {
            color: 'navy',
        },
    }))
    const classes = useStyles()
    return (
        <Tooltip 
            title = {props.title || "definiti proprietatea \"title\""} 
            arrow = {props.arrow || false}
            placement =  {props.placement || "bottom"}
            classes={{
                tooltip: classes.customTooltip,
                arrow: classes.customArrow
              }}
            {...props}
        >
            {props.children}
        </Tooltip>
            
        
    )
}
