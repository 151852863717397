import { AppBar, Button, CssBaseline, Drawer, Grid, IconButton, Link, makeStyles, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React, {  Fragment } from 'react';
// import { useRouter } from 'next/router'
import { FaBalanceScaleRight, FaCarrot, FaCog, FaEnvelopeOpenText, FaHandshake, FaHome, FaPowerOff, FaQuestionCircle, FaUsers } from 'react-icons/fa';
import { withRouter } from "react-router-dom";
// import Head from '../components/Head'
import Copyright from '../components/Copyright';
import { useHistory } from 'react-router-dom';
import logo from '../assets/logo.svg'


// import Logo from '../assets/AQUIS.svg'

import config from '../config'


const drawerWidth = 100;

const useStyles = makeStyles(theme => {
  // console.log("theme:", theme)
  return {
    root: {
      display: 'flex', width: "100%",
      position: "relative"
    },
    toolbar: {
      paddingLeft: "10px",
      paddingTop: 0,
      paddingRight: 0, // keep right padding when drawer closed
      // background: "rgba(14,7,107,1)",
      background: theme.palette.primary.dark,
      // background: "rgb(255,255,255)",
      // background: "linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(148,182,233,1) 240%)",
      // color: theme.palette.primary.contrastText
      boxShadow: "none",
      color: "white"
      
    },
    toolbarPaper: {
      boxShadow: "none",
      border: "none"
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      // padding: '0 20px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: 'relative',
      // whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(0),
      [theme.breakpoints.up('sm')]: {
        //    width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      // flexGrow: 1
      height: '100vh',
      overflow: 'auto',
      width: "100%",

    },
    container: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),


    },
    backContainer: {
      position: 'absolute',
      top: 0, left: 0, bottom: 0, right: 0,
      backgroundImage: `url('/cioara.jpg')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      border: '5px solid red',
      padding: '20px',
      opacity: 0.07,
      zIndex: -1,
      zoom: 1
    },
    paper: {
      padding: theme.spacing(0),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
    wrapText: {
      wordWrap: 'break-word'
    },
    appNameTypography: {
      color: theme.palette.primary.dark,
      fontWeight: 800,
      textShadow: "0px 0px 0.2rem white"
    }
  }
});



const Layout = (props) => {

  const theme = useTheme()
  const width = useMediaQuery(theme.breakpoints.up('sm'))
const history = useHistory()
  const iconSize = "2rem"
  // console.log("width: ", width)
  // const router = useHistory()
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

const infoOpenHandler = () => history.push("/info")
  return (
    <div className={classes.root}>

     
      <CssBaseline />
     
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
      
        <Toolbar className={classes.toolbar} variant="dense" classes = {{root: classes.toolbarPaper, dense: classes.toolbarPaper}}>
          <IconButton

            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Grid container justifyContent="space-between" alignItems="center" spacing={0}>

            {/* <img src={Logo} style={{ height: "48px", width: "auto" }} /> */}

            <IconButton color="inherit" onClick={() => props.history.push('/')}><FaHome /></IconButton>

            <Grid item xs align="left" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            
              <Typography variant={width ? "h4" : "caption"}
                className = {classes.appNameTypography}
                onClick={() => props.history.push('/')}>&nbsp;&nbsp;
                {config.APP_NAME} 
                <img src = {logo} style = {{height: "65px", width: "auto", marginTop: 0, marginBottom: "-30px", opacity:1}} />
                
               
              </Typography>




            </Grid>
            {width && (
            <Grid item xs={3} align="right">
            versiunea 2.0
          </Grid>
            )}

            <Grid item>
              <IconButton color="inherit" onClick={
                infoOpenHandler}
                style={{ marginRight: "8px" }}
              >
                <FaQuestionCircle size="1.2rem"/>
              </IconButton>
            </Grid>


          </Grid>


        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}

        open={open}
      >
        <div className={classes.toolbarIcon}>

          <IconButton onClick={handleDrawerClose} aria-label="close side menu">
            <ChevronLeftIcon />
          </IconButton>


        </div>

        <Grid container justifyContent="space-around" alignItems="center" style={{ marginTop: '1em' }}>

          <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '1rem' }} >
            <Button onClick={() => props.history.push('/')}>
              <span style={{ display: "block" }}>
                <FaEnvelopeOpenText style={{ fontSize: iconSize, color: 'blue' }} /><br />
                <Typography variant="caption" color="inherit" >
                  <div style={{ lineHeight: 1.2 }}>HOME</div>
                </Typography>
              </span>
            </Button>
          </Grid>



          {/* <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '1rem' }} >
            <Button onClick={() => props.history.push('/agenda')}>
              <span style={{ display: "block" }}>
                <FaUsers style={{ fontSize: iconSize, color: 'orange', opacity: 0.7 }} /><br />
                <Typography variant="caption" color="inherit">AGENDĂ</Typography>
              </span>
            </Button>
          </Grid> */}

          <Fragment>

            <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '1rem' }} >
              <Button onClick={() => props.history.push('/setari')}>
                <span style={{ display: "block" }}>
                  <FaCog style={{ fontSize: iconSize, color: 'mediumorchid' }} /><br />
                  <Typography variant="caption">SETĂRI</Typography>
                </span>
              </Button>
            </Grid>
          </Fragment>



        </Grid>



        {/* <Divider /> */}
        {/* <List>{secondaryListItems}</List> */}
      </Drawer>
      <main className={classes.content} >
        <div className={classes.appBarSpacer} style={{ marginBottom: "-15px" }} />
        {/* <Container maxWidth="xl" className={classes.container} > */}

        {props.children}


        {/* </Container> */}
        <div className={classes.backContainer} />
        <Copyright />
      </main>
    </div>
  );
}

export default withRouter(Layout)