import { Button, CircularProgress, Grid, Typography, useTheme } from '@material-ui/core'
import React from 'react'
import { Fragment } from 'react'
import RichCard from '../../../components/RichCard'
import { useQuery } from '@apollo/client'
import { QUERY_ALL_DATA } from '../../../apollo/resolvers'
import { Alert } from '@material-ui/lab'
import download from 'downloadjs'

const Btn = props => <Button variant="contained" size="large" color="primary" fullWidth
    style={{ display: "block" }}
    {...{...props}} >
    <Fragment>
        <Typography variant="h3">{props.title}</Typography>
        <Typography variant="caption">{props.desc}</Typography>
    </Fragment>
</Button>

const encoder = obj => {
    const keys = Object.keys(obj)
    return  keys.reduce( (ac, key) => ({...ac, [key]: obj[key] && encodeURI(obj[key])}), {})
}

export default function Backup(props) {
    const theme = useTheme()
    const { data, loading, error } = useQuery(QUERY_ALL_DATA)

    const backupAllHandler = () => {
        const {getAllData} = data || {}
        let obj = JSON.stringify({
            Contact: getAllData.Contact.map( e => (encoder({...e})) ),
            Setting: getAllData.Setting.map( e => ({...e})),
            Expeditor: getAllData.Expeditor.map( e => (encoder({...e})) ),
            Destinatar: getAllData.Destinatar.map( e => (encoder({...e})) ),
        })
        download(obj, new Date().toISOString().split('T')[0] + "_netplix_backup_all.json")
    }

    const backupContacts = () => {
        const {getAllData} = data || {}
        console.log("data:", getAllData)
        let obj = JSON.stringify({
            Contact: getAllData.Contact.map( e => (encoder({...e}))),
        })
        download(obj, new Date().toISOString().split('T')[0] + "_netplix_backup_contacts.json")
    }

    const backupSettings = () => {
        const {getAllData} = data || {}
        console.log("data:", getAllData)
        let obj = JSON.stringify({
            Setting: getAllData.Setting.map( e => ({...e})),
        })
        download(obj, new Date().toISOString().split('T')[0] + "_netplix_backup_settings.json")
    }

    return (
        <Fragment>
            {error && <Alert severity="error">
                A apărut următoarea eroare în procesul de interogare a datelor<br />
                {error.toString()}
            </Alert>}
            {loading && <CircularProgress />}
            {data && (
                <RichCard title="EFECTUARE COPIE DE SIGURANȚĂ" contentStyle={{ height: "65vh" }} style={{ height: "70vh" }}>
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                            <Btn
                                style = {{background: theme.palette.secondary.dark, display: "block"}}
                                title="BACKUP COMPLET"
                                desc="contacte, destinatari, exepeditori si setari"
                                onClick={backupAllHandler}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Btn
                                title="BACKUP CONTACTE"
                                desc={<Fragment>se vor salva doar contactele existente <strong>({data.getAllData.Contact.length})</strong></Fragment>}
                                onClick={() => alert('btn1')}
                                onClick = {backupContacts}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Btn
                                title="BACKUP CONFIGURĂRI"
                                desc="copia de siguranță va conține doar configurările aplicației (plicuri, etc)"
                                onClick={() => alert('btn1')}
                                onClick = {backupSettings}
                            />
                        </Grid>
                    </Grid>
                </RichCard>
            )}

        </Fragment>
    )
}
